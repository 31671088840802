export enum ESocialLinkType {
    facebook = 1,
    instagram = 2,
    tiktok = 3,
    youtube = 4,
}

export interface ISocialLink {
    linkId: number;
    socialId: number;
    type: ESocialLinkType;
    translations: Record<string, ISocialLinkTranslation>;
}

export interface ISocialLinkTranslation {
    url: string;
}
