module.exports = [
    {
        navLinkId: 1,
        type: 'landing-page-1',
        as: 'button',
        labelKey: 'nav.new.in',
        links: [],
    },
    { navLinkId: 2, type: 'landing-page-2', as: 'button', labelKey: 'nav.bestseller', links: [] },
    { navLinkId: 3, type: 'landing-page-3', as: 'button', labelKey: 'nav.collections', links: [] },
    {
        navLinkId: 4,
        type: '',
        labelKey: 'nav.our.offer',
        as: 'button',
        links: [
            { to: '/brands', as: 'link', labelKey: 'nav.brands', links: [] },
            { to: '/categories', as: 'link', labelKey: 'nav.categories', links: [] },
        ],
    },
    {
        navLinkId: 5,
        type: 'catalog',
        labelKey: 'nav.catalogs',
        as: 'button',
        links: [],
    },
    { navLinkId: 9, type: '', to: '/private-label', as: 'link', labelKey: 'nav.private', links: [] },
    { navLinkId: 6, type: '', to: '/about-us', as: 'link', labelKey: 'nav.about', links: [] },
    { navLinkId: 7, type: '', to: '/blog', as: 'link', labelKey: 'nav.blog', links: [] },
    { navLinkId: 8, type: '', to: '/contact', as: 'link', labelKey: 'nav.contact', links: [] },
];
