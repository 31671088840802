import { useLocation } from '@reach/router';
import urlParser from 'url';

const useCheckIfInternal = (url: string | undefined): boolean | undefined => {
    const { hostname } = useLocation();
    if (!url) return;
    const parsedUrl = urlParser.parse(url);
    return parsedUrl.hostname === null || parsedUrl.hostname === hostname;
};

export default useCheckIfInternal;
