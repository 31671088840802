import axios from "axios";
import { config } from '../config';

const { endpoints, languages } = config;

export function fetchSearchResults(phrase: string, page: number = 1, perPage: number = 20) {
    return axios.get(
        endpoints.search(phrase, page, perPage),
        {
            headers: { ...languages.defaultLanguageHeader }
        }
    );
}