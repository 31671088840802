import React from 'react';

import { link, notLink } from './breadcrumb-link.module.scss';
import { IFinalBreadcrumb } from '../../models/breadcrumb.model';
import {
    getInteractiveElementProps,
    getInteractiveElementTag,
} from '../../utils/get-interactive-element-tag';

interface IBreadcrumbLinkProps {
    className?: string;
    isNotLink?: boolean;
    breadcrumb: IFinalBreadcrumb;
}

const BreadcrumbLink: React.FC<IBreadcrumbLinkProps> = ({
    className = '',
    breadcrumb,
    isNotLink = false,
}) => {
    const Tag = getInteractiveElementTag(isNotLink ? 'element' : 'link');
    const tagProps = getInteractiveElementProps(isNotLink ? 'element' : 'link', {
        to: breadcrumb.slug,
    });
    return (
        <Tag
            className={`${link} ${className} ${isNotLink ? notLink : ''}`}
            title={breadcrumb.label}
            {...tagProps}
        >
            {breadcrumb.label}
        </Tag>
    );
};

export default BreadcrumbLink;
