import React, { useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, button, open, linkEl, dropdown } from './dropdown-nav.module.scss';
import Arrow from '../../assets/images/svg/arrow.svg';
import IPrimaryNavLink from '../../models/primary-nav-link.model';
import INavLink from '../../models/nav-link.model';

import NavLink from '../atoms/nav-link';
import Dropdown from '../hoc/dropdown';

interface IDropdownNavProps {
    navItem: IPrimaryNavLink | INavLink;
    className?: string;
}

const DropdownNav = ({ navItem, className = '' }: IDropdownNavProps) => {
    const { t } = useI18next();
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenDropdown = () => setIsOpen(true);
    const handleCloseDropdown = () => setIsOpen(false);

    return (
        <div className={`${container} ${className}`}>
            <button
                className={`${button} ${isOpen ? open : ''}`}
                type="button"
                onClick={handleOpenDropdown}
            >
                {t(
                    `${(navItem as IPrimaryNavLink).label || (navItem as IPrimaryNavLink).labelKey}`
                )}
                <Arrow />
            </button>

            <Dropdown
                isOpen={isOpen}
                closeFunction={handleCloseDropdown}
                className={`${dropdown} ${isOpen ? open : ''}`}
            >
                <ul>
                    {(navItem as IPrimaryNavLink).links?.map((link, idx: number) => {
                        if (link.links && link.links.length > 0) {
                            return <DropdownNav navItem={link} key={`nav-inner-dropdown-${idx}`} />;
                        }

                        return (
                            <li key={idx}>
                                <NavLink link={link} className={linkEl} />
                            </li>
                        );
                    })}
                </ul>
            </Dropdown>
        </div>
    );
};

export default DropdownNav;
