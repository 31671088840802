// extracted by mini-css-extract-plugin
export var actions = "header-module--actions--V6Jkt";
export var certificateItem = "header-module--certificate-item--A3ZkI";
export var certificateList = "header-module--certificate-list--j4vwd";
export var certificatesRow = "header-module--certificates-row--dP1ZZ";
export var gradientAnimation = "header-module--gradient-animation--1vAYL";
export var header = "header-module--header--2rrv9";
export var languageSelect = "header-module--language-select--8Xx9F";
export var loginLinkText = "header-module--login-link-text--1zYQX";
export var logoIso = "header-module--logo-iso--7ctlF";
export var logoLink = "header-module--logo-link--QNObB";
export var middleContainer = "header-module--middle-container--m1T0H";
export var middleRow = "header-module--middle-row--jw-bl";
export var navigation = "header-module--navigation--W1riA";
export var navigationRow = "header-module--navigation-row--IFb2k";
export var search = "header-module--search--uifzX";
export var separator = "header-module--separator--1h700";
export var sideActions = "header-module--side-actions--T9-JJ";