import React, { CSSProperties, RefObject, useEffect, useRef } from 'react';

import { container, open, left } from './dropdown.module.scss';

type TDropdownExpandDirection = 'bottom' | 'left';

interface IDropdownProps {
    className?: string;
    isOpen: boolean;
    buttonRef?: RefObject<any>;
    closeFunction(): any;
    expandDirection?: TDropdownExpandDirection;
    style?: CSSProperties;
}

const Dropdown: React.FC<IDropdownProps> = ({
    className = '',
    isOpen = false,
    closeFunction = () => {},
    children,
    buttonRef,
    expandDirection = 'bottom',
    style,
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const expandDirectionClass = expandDirectionClasses[expandDirection];

    useEffect(() => {
        const handleDropdownClose = (event: any): void => {
            const dropdown = dropdownRef?.current;
            if (!dropdown) return;
            if (
                event.target === dropdown ||
                dropdown.contains(event.target) ||
                event.target === buttonRef?.current
            )
                return;
            closeFunction();
        };

        if (isOpen) {
            document.addEventListener('click', handleDropdownClose);
        }

        return () => {
            document.removeEventListener('click', handleDropdownClose);
        };
    }, [isOpen, closeFunction]);

    return (
        <div
            className={`${container} ${className} ${isOpen ? open : ''} ${expandDirectionClass}`}
            ref={dropdownRef}
            style={style}
        >
            {children}
        </div>
    );
};

const expandDirectionClasses: Record<TDropdownExpandDirection, string> = {
    bottom: '',
    left: left,
};

export default Dropdown;
