// extracted by mini-css-extract-plugin
export var absolute = "loader-module--absolute--lX5TH";
export var circle = "loader-module--circle--S+uDM";
export var circleBox = "loader-module--circle-box--KcjKS";
export var circleOne = "loader-module--circle-one--BFi3v";
export var circleTwo = "loader-module--circle-two--DwzPC";
export var container = "loader-module--container--4viHi";
export var dot = "loader-module--dot--gzmUG";
export var dotOne = "loader-module--dot-one--tdC6F";
export var dotTwo = "loader-module--dot-two--P0hZk";
export var rotate = "loader-module--rotate--wXZQA";
export var small = "loader-module--small--eBfl6";
export var violet = "loader-module--violet--CoPEz";
export var white = "loader-module--white--0zd34";