// extracted by mini-css-extract-plugin
export var alignCenter = "banner-module--align-center--9gZa7";
export var alignEnd = "banner-module--align-end--ya5+b";
export var button = "banner-module--button--s2UUI";
export var container = "banner-module--container--upOpT";
export var content = "banner-module--content--wTMQf";
export var header = "banner-module--header--vYZ6K";
export var horizontalBox = "banner-module--horizontal-box--kEVPw";
export var horizontalSpacer = "banner-module--horizontal-spacer--dsTiV";
export var slide = "banner-module--slide--x+8Lu";
export var subtitleImageBox = "banner-module--subtitle-image-box--Ie8oR";
export var text = "banner-module--text--ntvGW";
export var textShadow = "banner-module--text-shadow--qNJB5";
export var verticalBox = "banner-module--vertical-box--W1HDb";