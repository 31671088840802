import React, { useContext, useEffect, useState } from 'react';
import { GatsbyImage, GatsbyImageProps, getImage, withArtDirection } from 'gatsby-plugin-image';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import {
    container,
    horizontalBox,
    slide,
    horizontalSpacer,
    verticalBox,
    content,
    alignCenter,
    alignEnd,
    textShadow,
    header,
    text,
    subtitleImageBox,
    button,
} from './banner.module.scss';
import { IBanner } from '../../models/banner.model';
import { IMedia } from '../../models/media.model';
import { config } from '../../config';
import { MainContext } from '../../layouts/main-layout.context';
import useMediaQuery from '../../hooks/use-media-query';
import { getMediaWithRelation } from '../../utils/get-relation';
import { useTranslation } from '../../hooks/use-translation';
import { useMediaTranslation } from '../../hooks/use-media-translation';
import useCheckIfInternal from '../../hooks/use-check-if-internal';

import Title, { ITitle } from '../atoms/title';
import Button from '../atoms/button';
import RatioImage from '../atoms/ratio-image';

const { relations, translationKeys } = config;

export interface IBannerProps {
    className?: string;
    ratioClass?: string;
    onlyImage?: boolean;
    isSlide?: boolean;
    hasTextShadow?: boolean;
    titleData?: ITitle;
    banner: IBanner;
    loading?: GatsbyImageProps['loading'];
}

interface IBannerImages {
    main: {
        data?: IGatsbyImageData;
        alt?: string;
    };
    subtitle: {
        data?: IGatsbyImageData;
        alt?: string;
    };
}

const Banner: React.FC<IBannerProps> = ({
    className = '',
    isSlide = false,
    hasTextShadow = false,
    titleData = {},
    ratioClass,
    banner,
    onlyImage = false,
}) => {
    const { setShowModal } = useContext(MainContext);
    const isMobile = useMediaQuery(500);
    const { title, description, buttonText, buttonUrl } = useTranslation(
        banner,
        translationKeys.banner
    );

    const media = useMediaTranslation(banner);

    const [images, setImages] = useState<IBannerImages>(getImages(media));
    const urlIsInternal = useCheckIfInternal(buttonUrl);

    const {
        contentWidth = 50,
        contentAlign = 'start',
        horizontalStart = '50',
        verticalStart = 'center',
        verticalStartMobile = 'end',
    } = banner;

    const actualVerticalStart = isMobile
        ? getPositionValue(verticalStartMobile)
        : getPositionValue(verticalStart);
    const posXStart = getPositionValue(horizontalStart);

    useEffect(() => {
        const newImages = getImages(media);
        setImages(newImages);
    }, [media]);

    return (
        <div className={`${container} ${className}`}>
            <RatioImage
                ratioClass={ratioClass || ''}
                image={images.main.data}
                alt={images.main.alt || ''}
            />
            {!onlyImage && (
                <div
                    className={`${horizontalBox} ${isSlide ? slide : ''}`}
                    style={{
                        ...(typeof posXStart === 'string'
                            ? {
                                  justifyContent: posXStart,
                              }
                            : {}),
                    }}
                >
                    {typeof posXStart === 'number' && (
                        <span
                            className={horizontalSpacer}
                            style={{
                                flex: `0 0 ${posXStart}%`,
                            }}
                        />
                    )}
                    <div
                        className={verticalBox}
                        style={{
                            width: `${contentWidth}%`,
                            ...(typeof actualVerticalStart === 'string'
                                ? {
                                      justifyContent: actualVerticalStart,
                                  }
                                : {}),
                        }}
                    >
                        {typeof actualVerticalStart === 'number' && (
                            <span
                                style={{
                                    flex: `0 0 ${actualVerticalStart}%`,
                                }}
                            />
                        )}
                        <div
                            className={`
                                ${content} 
                                ${getAlignClass(contentAlign)}
                                ${hasTextShadow ? textShadow : ''}
                            `}
                        >
                            {title && (
                                <Title className={header} {...titleData}>
                                    {title}
                                </Title>
                            )}
                            {images.subtitle.data ? (
                                <div className={subtitleImageBox}>
                                    <GatsbyImage
                                        alt={images.subtitle.alt || ''}
                                        image={images.subtitle.data}
                                    />
                                </div>
                            ) : (
                                description && <p className={text}>{description}</p>
                            )}
                            {buttonUrl && buttonText && (
                                <Button
                                    className={button}
                                    as={urlIsInternal ? 'link' : 'externalLink'}
                                    to={buttonUrl}
                                    onClick={() => setShowModal(true)}
                                    color="white"
                                >
                                    {buttonText}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

function getPositionValue(position: string): string | number {
    if (!isNaN(Number(position))) return Number(position);
    if (position === 'start') return 'flex-start';
    if (position === 'end') return 'flex-end';
    return 'center';
}

function getAlignClass(align: IBanner['contentAlign']): string {
    if (align === 'center') return alignCenter;
    if (align === 'end') return alignEnd;
    return '';
}

function getImages(media: IMedia[]): IBannerImages {
    const mainImageMedia = getMediaWithRelation(media, relations.mainImage);
    const mainImageMobileMedia = getMediaWithRelation(media, relations.mainImageMobile);
    const subtitleImageMedia = getMediaWithRelation(media, relations.secondaryImage, false);

    const mainImage = mainImageMedia?.remoteImage && getImage(mainImageMedia.remoteImage);
    const mainImageMobile =
        mainImageMobileMedia?.remoteImage && getImage(mainImageMobileMedia.remoteImage);
    const subtitleImage =
        subtitleImageMedia?.remoteImage && getImage(subtitleImageMedia.remoteImage);

    const mainImages =
        mainImage &&
        mainImageMobile &&
        withArtDirection(mainImage, [
            {
                image: mainImageMobile,
                media: `(max-width: 499px)`,
            },
        ]);

    return {
        main: {
            data: mainImages,
            alt: mainImageMedia?.alt,
        },
        subtitle: {
            data: subtitleImage,
            alt: subtitleImageMedia?.alt,
        },
    };
}

export default Banner;
