// extracted by mini-css-extract-plugin
export var container = "gov-dialog-module--container--JOtBa";
export var exit = "gov-dialog-module--exit--XkUST";
export var flag = "gov-dialog-module--flag----+Ub";
export var logos = "gov-dialog-module--logos--RpVkm";
export var mainButton = "gov-dialog-module--main-button--1T8cp";
export var modalClosed = "gov-dialog-module--modal-closed--4AW6f";
export var modalClosedDesktop = "gov-dialog-module--modal-closed-desktop--gPMIy";
export var modalOpened = "gov-dialog-module--modal-opened--LnHTj";
export var modalShowTextMobile = "gov-dialog-module--modal-show-text-mobile--90sk2";
export var ratio = "gov-dialog-module--ratio--WLEQC";
export var scrollingDown = "gov-dialog-module--scrollingDown--GeKS5";
export var scrollingUp = "gov-dialog-module--scrollingUp--4PVks";
export var text = "gov-dialog-module--text--nflsE";