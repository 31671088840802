import React, { SetStateAction, useEffect, useState } from 'react';

import { fetchSearchResults } from '../api/search';
import { IApiPagination } from '../models/api-pagination';
import { TStatus } from '../models/status.model';
import { IProduct } from '../models/product.model';

interface IUseSearchReturn {
    phrase: string;
    setPhrase: React.Dispatch<SetStateAction<string>>;
    results: IProduct[];
    status: TStatus;
    pagination: IApiPagination;
    getNextPage(): void;
}

interface IUseSearchConfig {
    perPage?: number;
    onResponse?(status: TStatus): void;
}

const initialPagination: IApiPagination = {
    totalCount: 0,
    pageCount: 0,
    currentPage: 0,
    perPage: 20,
};

const useSearch = (config: IUseSearchConfig | undefined): IUseSearchReturn => {
    const { perPage = 20, onResponse } = config || {};
    const [phrase, setPhrase] = useState<string>('');
    const [results, setResults] = useState<IProduct[]>([]);
    const [status, setStatus] = useState<TStatus>('idle');
    const [pagination, setPagination] = useState<IApiPagination>({ ...initialPagination, perPage });

    const getResults = async (value: string, pagination: IApiPagination, isNewPhrase: boolean) => {
        if (status === 'loading') return;
        setStatus('loading');
        try {
            const response = await fetchSearchResults(
                value,
                pagination.currentPage + 1,
                pagination.perPage
            );
            setStatus('success');
            setPagination(response.data.pagination);
            setResults(isNewPhrase ? response.data.items : [...results, ...response.data.items]);
            if (typeof onResponse === 'function') {
                onResponse('success');
            }
        } catch {
            setStatus('error');
            if (typeof onResponse === 'function') {
                onResponse('error');
            }
        }
    };

    const getNextPage = () => {
        if (pagination.currentPage === pagination.pageCount) return;
        getResults(phrase, pagination, false).then();
    };

    useEffect(() => {
        if (!phrase) {
            setResults([]);
            setStatus('idle');
        }
        if (phrase.length >= 3) {
            setResults([]);
            getResults(phrase, { ...initialPagination, perPage }, true).then();
        }
    }, [phrase]);

    return {
        phrase,
        setPhrase,
        getNextPage,
        results,
        status,
        pagination,
    };
};

export default useSearch;
