import React, { CSSProperties, useRef, useState } from 'react';

import { container, icon, dropdownContainer, dropdown, linkItem } from './social-link.module.scss';
import { config } from '../../config';
import FacebookIcon from '../../assets/images/svg/facebook.svg';
import YouTubeIcon from '../../assets/images/svg/youtube.svg';
import InstagramIcon from '../../assets/images/svg/instagram.svg';
import TikTokIcon from '../../assets/images/svg/tiktok.svg';
import { ISocialWidgetItem } from '../../models/social-widget-item.model';
import { ESocialLinkType, ISocialLink } from '../../models/social-link.model';
import { useTranslation } from '../../hooks/use-translation';

import Dropdown from '../hoc/dropdown';
import SocialWidgetLink from './social-widget-link';

const { translationKeys } = config;

interface ISocialLinkProps {
    className?: string;
    item: ISocialLink | ISocialWidgetItem;
    name?: string;
    dropdownBackgroundColor?: CSSProperties['color'];
}

const SocialLink: React.FC<ISocialLinkProps> = ({
    className = '',
    item,
    name,
    dropdownBackgroundColor,
}) => {
    const { type } = item;
    const { url } = useTranslation(item, translationKeys.socialLink);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const Icon = icons[type];
    const label = `${name ? `${name} ` : ''}${labels[type]}`;

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleCloseDropdown = () => {
        setIsDropdownOpen(false);
    };

    if (assertIsSocialLink(item)) {
        return (
            <a
                aria-label={label}
                title={label}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className={`${container} ${className}`}
            >
                <Icon className={icon} />
            </a>
        );
    }

    return (
        <div className={`${className} ${dropdownContainer}`}>
            <button ref={buttonRef} className={container} onClick={toggleDropdown}>
                <Icon className={icon} />
            </button>
            <Dropdown
                className={dropdown}
                isOpen={isDropdownOpen}
                closeFunction={handleCloseDropdown}
                buttonRef={buttonRef}
                expandDirection="left"
                style={dropdownBackgroundColor ? { backgroundColor: dropdownBackgroundColor } : {}}
            >
                <ul>
                    {(item as ISocialWidgetItem).links.map((link, index) => {
                        return (
                            <li key={`link-item-${index}`} className={linkItem}>
                                <SocialWidgetLink socialWidgetLink={link} />
                            </li>
                        );
                    })}
                </ul>
            </Dropdown>
        </div>
    );
};

function assertIsSocialLink(item: ISocialLink | ISocialWidgetItem): item is ISocialLink {
    return 'translations' in item;
}

const labels: Record<ESocialLinkType, string> = {
    [ESocialLinkType.facebook]: 'facebook',
    [ESocialLinkType.instagram]: 'instagram',
    [ESocialLinkType.tiktok]: 'tiktok',
    [ESocialLinkType.youtube]: 'youtube',
};

const icons: Record<ESocialLinkType, React.SVGFactory> = {
    [ESocialLinkType.facebook]: FacebookIcon,
    [ESocialLinkType.instagram]: InstagramIcon,
    [ESocialLinkType.tiktok]: TikTokIcon,
    [ESocialLinkType.youtube]: YouTubeIcon,
};

export default SocialLink;
