import { useState, useEffect } from 'react';

export default function useWindowScroll() {
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollX(window.scrollX);
            setScrollY(window.scrollY);
        };

        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return {
        x: scrollX,
        y: scrollY,
    };
}
