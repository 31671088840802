import React, { SyntheticEvent } from 'react';

import { box } from './infinite-scroll-box.module.scss';

interface IInfiniteScrollBoxProps {
    className?: string;
    onScroll?(): void;
    triggerOffset?: number;
}

const InfiniteScrollBox: React.FC<IInfiniteScrollBoxProps> = ({
    className = '',
    onScroll = () => {},
    triggerOffset = 100,
    children,
}) => {
    const handleScroll = (event: SyntheticEvent) => {
        const target = event.target as HTMLDivElement;
        const scrollHeight = target.scrollHeight;
        const scrolled = target.scrollTop + target.offsetHeight;

        if (scrolled < scrollHeight - triggerOffset) return;

        onScroll();
    };

    return (
        <div className={`${box} ${className}`} onScroll={handleScroll}>
            {children}
        </div>
    );
};

export default InfiniteScrollBox;
