import {useEffect, useState} from "react";

const useMediaQuery = (mediaQuery: number): boolean => {
    const [isMediaQueryMet, setIsMediaQueryMet] = useState(false);

    useEffect(() => {
        const handleResize = (): void => {
            setIsMediaQueryMet(window.innerWidth < mediaQuery);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return isMediaQueryMet;
}

export default useMediaQuery;