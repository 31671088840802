import React, { useRef } from 'react';

import { container, button, active, dot } from './slider-dots.module.scss';

interface Props {
    className?: string;
    activeIndex: number;
    count: number;
    disabled?: boolean;
    onClick?(dotIndex: number): void;
}

const SliderDots: React.FC<Props> = ({
    className = '',
    activeIndex,
    count,
    disabled = false,
    onClick = () => {},
}) => {
    const dotRef = useRef<HTMLButtonElement>(null);
    const countArr = Array.from(Array(count).keys());

    const handleClick = (dotIndex: number): void => {
        if (dotRef.current) {
            dotRef.current.blur();
        }
        onClick(dotIndex);
    };

    return (
        <div className={`${container} ${className}`}>
            {countArr.map((dotIndex) => {
                return (
                    <button
                        ref={dotRef}
                        key={`slider-dot-${dotIndex}`}
                        className={`
                            ${button}
                            ${activeIndex === dotIndex ? active : ''}`}
                        disabled={disabled}
                        aria-label={`Go to slide number ${dotIndex}`}
                        onClick={() => handleClick(dotIndex)}
                    >
                        <span className={dot} />
                    </button>
                );
            })}
        </div>
    );
};

export default SliderDots;
