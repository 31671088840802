module.exports = {
    mainImage: 1,
    secondaryImage: 2,
    mainImageMobile: 3,
    coverImage: 4,
    galleryImage: 5,
    landingPageIcons: 100,
    landingPageLogo: 101,
    landingPageVariantImage: 102,
};
