import React, { SyntheticEvent, useContext, useRef } from 'react';

import { modal } from './modal.module.scss';
import { MainContext } from '../../layouts/main-layout.context';

const Modal: React.FC = ({ children }) => {
    const modalRef = useRef(null);
    const { setShowModal } = useContext(MainContext);

    const handleModalClose = (event: SyntheticEvent<any, Event>): void => {
        if (event.target === modalRef.current) {
            setShowModal(false);
        }
    };

    return (
        <div className={modal} ref={modalRef} onClick={handleModalClose}>
            {children}
        </div>
    );
};

export default Modal;
