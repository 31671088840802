import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { button, open, iconBox, dropdown, flag } from './language-select.module.scss';
import ChevronIcon from '../../assets/images/svg/chevron.svg';
import EnIcon from '../../assets/images/svg/flag-gb.svg';
import PlIcon from '../../assets/images/svg/flag-pl.svg';

import Dropdown from '../hoc/dropdown';

interface Props {
    className?: string;
}

const LanguageSelect: React.FC<Props> = ({ className = '' }) => {
    const { language, languages, pagePaths, defaultLanguage } = useI18next();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const FlagIcon = flagIcons[language];

    return (
        <button
            className={`
                ${button}
                ${className}
                ${isDropdownOpen ? open : ''}
            `}
            onClick={() => setIsDropdownOpen(true)}
            disabled={languages.length < 2}
        >
            {FlagIcon && <FlagIcon className={flag} />}
            {languages.length > 1 && (
                <>
                    <span className={iconBox}>
                        <ChevronIcon />
                    </span>
                    <Dropdown
                        isOpen={isDropdownOpen}
                        closeFunction={() => setIsDropdownOpen(false)}
                        className={dropdown}
                    >
                        {languages
                            .filter((lang) => lang !== language)
                            .map((lang) => {
                                const path =
                                    lang === defaultLanguage
                                        ? pagePaths[lang]
                                        : `/${lang}${pagePaths[lang]}`;
                                const Icon = flagIcons[lang];
                                return (
                                    <Link key={`language-${lang}`} to={path}>
                                        {Icon && <Icon className={flag} />}
                                    </Link>
                                );
                            })}
                    </Dropdown>
                </>
            )}
        </button>
    );
};

const flagIcons: Record<string, React.SVGFactory> = {
    pl: PlIcon,
    en: EnIcon,
};

export default LanguageSelect;
