module.exports = {
    home: 'home',
    homeBottom: 'homeBottom',
    blog: 'newIn',
    about: 'aboutUs',
    aboutWho: 'aboutUsWho',
    aboutTeam: 'aboutUsTeam',
    aboutBrands: 'aboutUsBrands',
    aboutPhilosophy: 'aboutUsPhilosophy',
    aboutBottom: 'aboutUsBottom',
    contact: 'contact',
    faq: 'faq',
    brands: 'brands',
    categories: 'categories',
    careers: 'careers',
    privateLabel: 'privateLabel',
    privateOne: 'privateLabelOne',
    privateTwo: 'privateLabelTwo',
    privateThree: 'privateLabelThree',
    privateFour: 'privateLabelFour',
    privateFive: 'privateLabelFive',
};
