module.exports = ({ API_URL }) => {
    return {
        authors: {
            all: (perPage = 1024) =>
                `${API_URL}/authors?per-page=${perPage}&expand=translations,media,posts`,
        },
        bannersCollections: {
            all: (perPage = 1024) =>
                `${API_URL}/banner-collections?per-page=${perPage}&expand=translations,banners,banners.media,banners.translations,banners.translations.media`,
        },
        careers: {
            offers: {
                all: (perPage = 1024) =>
                    `${API_URL}/careers?per-page=${perPage}&expand=media,translations`,
            },
            application: `${API_URL}/applications`,
        },
        categories: {
            all: `${API_URL}/product-categories`,
            tree: `${API_URL}/product-categories/tree`,
        },
        catalogs: {
            all: (perPage = 1024) => `${API_URL}/catalogs?per-page=${perPage}`,
        },
        certificates: {
            all: (perPage = 1024) => `${API_URL}/certificates?per-page=${perPage}`,
        },
        contact: `${API_URL}/contacts`,
        consents: {
            all: (perPage = 1024) =>
                `${API_URL}/consents?per-page=${perPage}&expand=translations,media`,
        },
        faq: {
            all: (perPage = 1024) => `${API_URL}/faqs?per-page=${perPage}`,
        },
        footer: `${API_URL}/footers`,
        govModal: `${API_URL}/government-program`,
        hederLogos: `${API_URL}/logo-iso`,
        homeVideo: `${API_URL}/get-home-video`,
        instagramFallback: `${API_URL}/instagram-photos`,
        landingPages: {
            all: (perPage = 1024) =>
                `${API_URL}/landing-pages?expand=banner,banner.translations,banner.media,products,products.media,products.rows,bestseller,bestseller.products,bestseller.products.translations,bestseller.products.media,media&per-page=${perPage}`,
        },
        landingMenu: `${API_URL}/landing-menu`,
        newsletter: {
            download: (key) => `${API_URL}/newsletters/file-download/${key}`,
            subscribe: `${API_URL}/newsletters/subscribe`,
        },
        posts: {
            all: (perPage = 1024) =>
                `${API_URL}/posts?per-page=${perPage}&expand=translations,media,author,relatedPosts,relatedPosts.media,relatedPosts.translations,tags,box1,box1.translations,box1.media,box2,box2.translations,box2.media,box3,box3.translations,box3.media,relatedProducts,relatedProducts.media,relatedProducts.translations`,
        },
        products: {
            all: (perPage = 1024) =>
                `${API_URL}/products?per-page=${perPage}&expand=translations,media,breadcrumbs,relatedProducts,familyProducts`,
        },
        redirects: {
            all: (perPage = 1024) => `${API_URL}/redirects?per-page=${perPage}`,
        },
        rings: {
            all: (perPage = 1024) => `${API_URL}/rings?expand=category&per-page=${perPage}`,
        },
        search: (phrase, page = 1, perPage = 20) =>
            `${API_URL}/product-search?phrase=${phrase}&per-page=${perPage}&page=${page}&expand=media,translations`,
        socials: {
            all: (perPage = 1024) => `${API_URL}/socials?expand=links&per-page=${perPage}`,
        },
        socialWidget: `${API_URL}/social-widgets`,
        tags: (perPage = 1024) => `${API_URL}/tags?per-page=${perPage}&expand=posts`,
    };
};
