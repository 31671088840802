module.exports = {
    langs: ['en', 'pl'],
    defaultLangKey: 'en',
    pageWordTranslations: {
        en: 'page',
        pl: 'strona',
    },
    defaultLanguageHeader: {
        'Accept-Language': 'en',
    }
}