import React, { RefObject, useRef } from 'react';

import { title, small, medium, reverse, column, introText } from './title.module.scss';
import useFontSizeFit from '../../hooks/use-font-size-fit';

export interface ITitle {
    Tag?: React.ElementType;
    intro?: string;
    introClass?: string;
    size?: 'small' | 'medium' | 'large';
    direction?: 'reverse' | 'column';
}

interface ITitleProps extends ITitle {
    className?: string;
    children: React.ReactNode;
    containerRef?: RefObject<any>;
}

const Title: React.FC<ITitleProps> = ({
    Tag = 'h2',
    className = '',
    intro = '',
    introClass = '',
    size,
    direction,
    children,
    containerRef,
}) => {
    const titleRef = useRef<HTMLHeadingElement>(null);
    const fontSize = useFontSizeFit(titleRef, containerRef);

    return (
        <Tag
            {...(fontSize
                ? {
                      style: { fontSize: `${fontSize}px` },
                  }
                : {})}
            ref={titleRef}
            className={`
                ${title}
                ${className}
                ${getSizeClass(size)}
                ${getFlexDirectionClass(direction)}
            `}
        >
            {intro && <span className={`${introText} ${introClass}`}>{intro}</span>}
            {children}
        </Tag>
    );
};

function getSizeClass(size: ITitleProps['size']): string {
    if (size === 'small') return small;
    if (size === 'medium') return medium;
    return '';
}

function getFlexDirectionClass(direction: ITitleProps['direction']): string {
    switch (direction) {
        case 'reverse':
            return reverse;
        case 'column':
        default:
            return column;
    }
}

export default Title;
