import React, { useContext } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    closeButton,
    languageLink,
    languageSelect,
    nav,
    navItem as navItemStyle,
    onlyMobile,
    open,
    ourOffer,
} from './navigation.module.scss';
import CloseIcon from '../../assets/images/svg/icon-close.svg';
import { MainContext } from '../../layouts/main-layout.context';
import { config } from '../../config';
import IPrimaryNavLink from '../../models/primary-nav-link.model';
import INavLink from '../../models/nav-link.model';
import { TQueryAllResult } from '../../models/query-all-result.model';
import { ICatalog } from '../../models/catalog.model';
import { getNodes } from '../../utils/get-nodes';
import { ILandingMenuLink, ILandingMenuLinksGroup } from '../../models/landing-link.model';

import NavLink from '../atoms/nav-link';
import Flag from '../atoms/flag';
import DropdownNav from './dropdown-nav';

interface Props {
    className?: string;
    navItems: IPrimaryNavLink[];
    isOpen: boolean;
    onClose?(): void;
}

interface INavigationQueryResults {
    allCatalog: TQueryAllResult<ICatalog>;
    allLandingMenu: TQueryAllResult<ILandingMenuLinksGroup>;
}

const Navigation: React.FC<Props> = ({ className = '', navItems, isOpen, onClose }) => {
    const { t, languages, pagePaths, defaultLanguage, language } = useI18next();
    const { setShowModal } = useContext(MainContext);
    const { allCatalog, allLandingMenu }: INavigationQueryResults = useStaticQuery(query);
    const catalogs = getNodes(allCatalog);
    const landingMenus = getNodes(allLandingMenu);
    const allNavItems = combineAllNavItems(navItems, landingMenus, catalogs, language);

    const handleClose = () => {
        if (typeof onClose !== 'function') return;
        onClose();
    };

    return (
        <nav className={`${className} ${nav} ${isOpen ? open : ''}`}>
            <button onClick={handleClose} className={closeButton}>
                <CloseIcon />
            </button>
            <div className={languageSelect}>
                {languages.map((lang) => {
                    const path =
                        lang === defaultLanguage ? pagePaths[lang] : `/${lang}${pagePaths[lang]}`;
                    return (
                        <Link
                            key={`language-link-${lang}`}
                            to={path}
                            className={languageLink}
                            aria-label={`${t('language.select')}: ${t(`lang.${lang}`)}`}
                        >
                            <Flag language={lang} />
                        </Link>
                    );
                })}
            </div>

            {Object.values(allNavItems).map((navItem: IPrimaryNavLink | INavLink, index) => {
                if (navItem.links && navItem.links.length > 0) {
                    return (
                        <DropdownNav
                            navItem={navItem}
                            className={ourOffer}
                            key={`nav-link-${index}`}
                        />
                    );
                }

                return (
                    <NavLink
                        key={`nav-link-${index}`}
                        link={navItem}
                        className={navItemStyle}
                        {...(navItem.as === 'button' ? { onClick: () => setShowModal(true) } : {})}
                    />
                );
            })}

            <NavLink
                link={{
                    labelKey: 'nav.login',
                    to: config.loginLink,
                    as: 'externalLink',
                }}
                className={`${navItemStyle} ${onlyMobile}`}
            />
        </nav>
    );
};

const query = graphql`
    query {
        allCatalog {
            edges {
                node {
                    ...catalogFields
                }
            }
        }

        allLandingMenu {
            edges {
                node {
                    ...landingMenuFields
                }
            }
        }
    }
`;

const combineAllNavItems = (
    navItems: IPrimaryNavLink[],
    landingMenus: ILandingMenuLinksGroup[],
    catalogs: ICatalog[],
    language: string
) => {
    const allItems = Object.values(navItems).map((navItem) => {
        if (navItem.type?.startsWith('landing')) {
            const landingNavItemsByType = landingMenus.find(
                (menu) => `landing-page-${menu.type}` === navItem.type
            );

            if (landingNavItemsByType) {
                navItem.links = getNavLinksFromLandingMenu(landingNavItemsByType.links, language);
            }
        }

        if (navItem.type?.startsWith('catalog')) {
            navItem.links = getNavLinksFromCatalogs(catalogs, language);
        }

        if (navItem.type && navItem.links.length === 0) {
            return null;
        }

        return navItem;
    });

    return allItems.filter((item): item is IPrimaryNavLink => item !== null);
};

const getNavLinksFromLandingMenu = (
    landingMenus: ILandingMenuLink[],
    language: string
): INavLink[] => {
    return landingMenus.map((menu) => {
        const { links } = menu;

        return {
            as: links && links.length > 0 ? 'button' : 'link',
            label: menu.translations[language]?.title,
            to: menu.translations[language]?.url,
            links: links && links.length > 0 ? getNavLinksFromLandingMenu(links, language) : [],
        };
    });
};

const getNavLinksFromCatalogs = (catalogs: ICatalog[], language: string): INavLink[] => {
    return catalogs.map((catalog) => {
        return {
            as: 'externalLink',
            label: catalog.translations[language]?.label,
            to: catalog.translations[language]?.url,
            links: [],
        };
    });
};

export default Navigation;
