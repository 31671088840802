import React from 'react';

import {
    container,
    small,
    white,
    violet,
    absolute,
    circleBox,
    circle,
    circleOne,
    circleTwo,
    dot,
    dotOne,
    dotTwo,
} from './loader.module.scss';

export interface ILoaderProps {
    className?: string;
    isAbsolute?: boolean;
    size?: 'small' | 'normal';
    color?: 'pink' | 'white' | 'violet';
}

const Loader: React.FC<ILoaderProps> = ({
    className = '',
    isAbsolute = false,
    size = 'normal',
    color = 'pink',
}) => {
    return (
        <div
            className={`
                ${container}
                ${className}
                ${isAbsolute ? absolute : ''}
                ${getSizeClass(size)}
                ${getColorClass(color)}
            `}
        >
            <div className={circleBox}>
                <div className={`${circle} ${circleOne}`}>
                    <span className={`${dot} ${dotOne}`} />
                </div>
                <div className={`${circle} ${circleTwo}`}>
                    <span className={`${dot} ${dotTwo}`} />
                </div>
            </div>
        </div>
    );
};

function getSizeClass(size: ILoaderProps['size']): string {
    if (size === 'small') return small;
    return '';
}

function getColorClass(color: ILoaderProps['color']): string {
    if (color === 'white') return white;
    if (color === 'violet') return violet;
    return '';
}

export default Loader;
