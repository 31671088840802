import { createContext, Dispatch, SetStateAction } from 'react';

interface IMainContext {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}

const defaultContext: IMainContext = {
    showModal: false,
    setShowModal: () => {},
};

export const MainContext = createContext(defaultContext);
