module.exports = {
    author: {
        nodeType: 'Author',
        schema: {
            profileId: 'Int',
            slug: 'String',
            media: '[Media]',
            posts: '[Post]',
            translations: 'AuthorTranslations',
        },
    },
    authorTranslations: {
        nodeType: 'AuthorTranslations',
        schema: {
            en: 'AuthorTranslation',
            pl: 'AuthorTranslation',
        },
    },
    authorTranslation: {
        nodeType: 'AuthorTranslation',
        schema: {
            title: 'String',
            firstName: 'String',
            lastName: 'String',
            slug: 'String',
            bio: 'String',
        },
    },
    banner: {
        nodeType: 'Banner',
        schema: {
            bannerId: 'Int',
            buttonUrl: 'String',
            publishedAt: 'Int',
            contentWidth: 'Int',
            horizontalStart: 'String',
            verticalStart: 'String',
            verticalStartMobile: 'String',
            translations: 'BannerTranslations',
            media: '[Media]',
        },
    },
    bannerTranslations: {
        nodeType: 'BannerTranslations',
        schema: {
            en: 'BannerTranslation',
            pl: 'BannerTranslation',
        },
    },
    bannerTranslation: {
        nodeType: 'BannerTranslation',
        schema: {
            title: 'String',
            description: 'String',
            buttonUrl: 'String',
            buttonText: 'String',
            media: '[Media]',
        },
    },
    bannersCollection: {
        nodeType: 'BannersCollection',
        schema: {
            placeId: 'String',
            banners: '[Banner]',
        },
    },
    brandSocial: {
        nodeType: 'BrandSocial',
        schema: {
            socialId: 'Int',
            color: 'String',
            sequence: 'Int',
            media: '[Media]',
            links: '[SocialLink]',
            translations: 'BrandSocialTranslations',
        },
    },
    brandSocialTranslations: {
        nodeType: 'BrandSocialTranslations',
        schema: {
            en: 'BrandSocialTranslation',
            pl: 'BrandSocialTranslation',
        },
    },
    brandSocialTranslation: {
        nodeType: 'BrandSocialTranslation',
        schema: {
            name: 'String',
        },
    },
    career: {
        nodeType: 'Career',
        schema: {
            offerId: 'Int',
            publishedAt: 'Int',
            translations: 'CareerTranslations',
            media: '[Media]',
        },
    },
    careerTranslations: {
        nodeType: 'CareerTranslations',
        schema: {
            en: 'CareerTranslation',
            pl: 'CareerTranslation',
        },
    },
    careerTranslation: {
        nodeType: 'CareerTranslation',
        schema: {
            title: 'String',
            taskContent: 'String',
            offerContent: 'String',
            location: 'String',
        },
    },
    catalog: {
        nodeType: 'Catalog',
        schema: {
            catalogId: 'Int',
            createdAt: 'Int',
            translations: 'CatalogTranslations',
        },
    },
    catalogTranslations: {
        nodeType: 'CatalogTranslations',
        schema: {
            en: 'CatalogTranslation',
            pl: 'CatalogTranslation',
        },
    },
    catalogTranslation: {
        nodeType: 'CatalogTranslation',
        schema: {
            label: 'String',
            url: 'String',
        },
    },
    category: {
        nodeType: 'Category',
        schema: {
            categoryId: 'Int',
            slug: 'String',
            businessMeaning: 'Int',
            productCount: 'Int',
            productIds: '[Int]',
            media: '[Media]',
            translations: 'CategoryTranslations',
            sequence: 'Int',
        },
    },
    categoryTranslations: {
        nodeType: 'CategoryTranslations',
        schema: {
            en: 'CategoryTranslation',
            pl: 'CategoryTranslation',
        },
    },
    categoryTranslation: {
        nodeType: 'CategoryTranslation',
        schema: {
            slug: 'String',
            name: 'String',
            shortDescription: 'String',
            description: 'String',
        },
    },
    categoryTree: {
        nodeType: 'CategoryTree',
        schema: {
            categoryId: 'Int',
            slug: 'String',
            businessMeaning: 'Int',
            productCount: 'Int',
            productIds: '[Int]',
            media: '[Media]',
            categories: '[CategoryTree]',
            translations: 'CategoryTranslations',
            sequence: 'Int',
        },
    },
    certificate: {
        nodeType: 'Certificate',
        schema: {
            articleId: 'Int',
            media: '[Media]',
            translations: 'CertificateTranslations',
        },
    },
    certificateTranslations: {
        nodeType: 'CertificateTranslations',
        schema: {
            en: 'CertificateTranslation',
            pl: 'CertificateTranslation',
        },
    },
    certificateTranslation: {
        nodeType: 'CertificateTranslation',
        schema: {
            name: 'String',
            url: 'String',
        },
    },
    consent: {
        nodeType: 'Consent',
        schema: {
            consentId: 'Int',
            isRequired: 'Boolean',
            name: 'String',
            forms: '[Form]',
            translations: 'ConsentTranslations',
        },
    },
    consentTranslations: {
        nodeType: 'ConsentTranslations',
        schema: {
            en: 'ConsentTranslation',
            pl: 'ConsentTranslation',
        },
    },
    consentTranslation: {
        nodeType: 'ConsentTranslation',
        schema: {
            name: 'String',
            content: 'String',
            media: 'Media',
        },
    },
    doc: {
        nodeType: 'Doc',
        schema: {
            name: 'String',
            translations: 'DocTranslations',
        },
    },
    docTranslations: {
        nodeType: 'DocTranslations',
        schema: {
            en: 'DocTranslation',
            pl: 'DocTranslation',
        },
    },
    docTranslation: {
        nodeType: 'DocTranslation',
        schema: {
            name: 'String',
            path: 'String',
        },
    },
    faq: {
        nodeType: 'Faq',
        schema: {
            translations: 'FaqTranslations',
        },
    },
    faqTranslations: {
        nodeType: 'FaqTranslations',
        schema: {
            pl: 'FaqTranslation',
            en: 'FaqTranslation',
        },
    },
    faqTranslation: {
        nodeType: 'FaqTranslation',
        schema: {
            question: 'String',
            answer: 'String',
        },
    },
    footerData: {
        nodeType: 'FooterData',
        schema: {
            footerItems: 'FooterItems',
        },
    },
    footerItems: {
        nodeType: 'FooterItems',
        schema: {
            footerLinks: 'FooterLinksData',
            contactData: 'FooterContactData',
            companyData: 'FooterCompanyData',
            addresses: '[FooterAddress]',
        },
    },
    footerLinksData: {
        nodeType: 'FooterLinksData',
        schema: {
            translations: 'FooterLinksDataTranslations',
            links: '[FooterLink]',
        },
    },
    footerLinksDataTranslations: {
        nodeType: 'FooterLinksDataTranslations',
        schema: {
            en: 'FooterLinksDataTranslation',
            pl: 'FooterLinksDataTranslation',
        },
    },
    footerLinksDataTranslation: {
        nodeType: 'FooterLinksDataTranslation',
        schema: {
            title: 'String',
        },
    },
    footerLink: {
        nodeType: 'FooterLink',
        schema: {
            id: 'Int',
            translations: 'FooterLinkTranslations',
        },
    },
    footerLinkTranslations: {
        nodeType: 'FooterLinkTranslations',
        schema: {
            en: 'FooterLinkTranslation',
            pl: 'FooterLinkTranslation',
        },
    },
    footerLinkTranslation: {
        nodeType: 'FooterLinkTranslation',
        schema: {
            title: 'String',
            url: 'String',
        },
    },
    footerContactData: {
        nodeType: 'FooterContactData',
        schema: {
            translations: 'FooterContactDataTranslations',
        },
    },
    footerContactDataTranslations: {
        nodeType: 'FooterContactDataTranslations',
        schema: {
            en: 'FooterContactDataTranslation',
            pl: 'FooterContactDataTranslation',
        },
    },
    footerContactDataTranslation: {
        nodeType: 'FooterContactDataTranslation',
        schema: {
            phone: 'String',
            email: 'String',
        },
    },
    footerCompanyData: {
        nodeType: 'FooterCompanyData',
        schema: {
            translations: 'FooterCompanyDataTranslations',
        },
    },
    footerCompanyDataTranslations: {
        nodeType: 'FooterCompanyDataTranslations',
        schema: {
            en: 'FooterCompanyDataTranslation',
            pl: 'FooterCompanyDataTranslation',
        },
    },
    footerCompanyDataTranslation: {
        nodeType: 'FooterCompanyDataTranslation',
        schema: {
            title: 'String',
            nip: 'String',
            regon: 'String',
        },
    },
    footerAddress: {
        nodeType: 'FooterAddress',
        schema: {
            id: 'Int',
            translations: 'FooterAddressTranslations',
        },
    },
    footerAddressTranslations: {
        nodeType: 'FooterAddressTranslations',
        schema: {
            en: 'FooterAddressTranslation',
            pl: 'FooterAddressTranslation',
        },
    },
    footerAddressTranslation: {
        nodeType: 'FooterAddressTranslation',
        schema: {
            title: 'String',
            street: 'String',
            city: 'String',
            country: 'String',
        },
    },
    form: {
        nodeType: 'Form',
        schema: {
            contextId: 'Int',
            systemName: 'String',
        },
    },
    govModal: {
        nodeType: 'GovModal',
        schema: {
            url: 'String',
            translations: 'GovModalTranslations',
            status: 'Int',
            media: '[Media]',
        },
    },
    govModalTranslations: {
        nodeType: 'GovModalTranslations',
        schema: {
            en: 'GovModalTranslation',
            pl: 'GovModalTranslation',
        },
    },
    govModalTranslation: {
        nodeType: 'GovModalTranslation',
        schema: {
            url: 'String',
            label: 'String',
        },
    },
    homeVideo: {
        nodeType: 'HomeVideo',
        schema: {
            media: '[Media]',
            translations: 'HomeVideoTranslations',
        },
    },
    homeVideoTranslations: {
        nodeType: 'HomeVideoTranslations',
        schema: {
            en: 'HomeVideoTranslation',
            pl: 'HomeVideoTranslation',
        },
    },
    homeVideoTranslation: {
        nodeType: 'HomeVideoTranslation',
        schema: {
            url: 'String',
        },
    },
    instagramFallback: {
        nodeType: 'InstagramFallback',
        schema: {
            photoId: 'Int',
            owner: 'Int',
            media: '[Media]',
        },
    },
    headerLogos: {
        nodeType: 'HeaderLogos',
        schema: {
            logo: 'Media',
            secondLogo: 'Media',
        },
    },
    landingMenu: {
        nodeType: 'LandingMenu',
        schema: {
            type: 'Int',
            links: '[LandingMenuLink]',
        },
    },
    landingMenuLink: {
        nodeType: 'LandingMenuLink',
        schema: {
            sequence: 'Int',
            translations: 'LandingMenuLinkTranslations',
            links: '[LandingMenuLink]',
        },
    },
    landingMenuLinkTranslations: {
        nodeType: 'LandingMenuLinkTranslations',
        schema: {
            en: 'LandingMenuLinkTranslation',
            pl: 'LandingMenuLinkTranslation',
        },
    },
    landingMenuLinkTranslation: {
        nodeType: 'LandingMenuLinkTranslation',
        schema: {
            title: 'String',
            url: 'String',
        },
    },
    landingPage: {
        nodeType: 'LandingPage',
        schema: {
            id: 'Int',
            slug: 'String',
            createdAt: 'Int',
            autoplayTime: 'Int',
            translations: 'LandingPageTranslations',
            banner: 'Banner',
            products: '[LandingPageProduct]',
            bestseller: 'LandingPageBestseller',
            media: '[Media]',
        },
    },
    landingPageBestseller: {
        nodeType: 'LandingPageBestseller',
        schema: {
            id: 'Int',
            backgroundColor: 'String',
            translations: 'LandingPageBestsellerTranslations',
            products: '[Product]',
        },
    },
    landingPageBestsellerTranslations: {
        nodeType: 'LandingPageBestsellerTranslations',
        schema: {
            en: 'LandingPageBestsellerTranslation',
            pl: 'LandingPageBestsellerTranslation',
        },
    },
    landingPageBestsellerTranslation: {
        nodeType: 'LandingPageBestsellerTranslation',
        schema: {
            title: 'String',
            slug: 'String',
            url: 'String',
        },
    },
    landingPageProduct: {
        nodeType: 'LandingPageProduct',
        schema: {
            id: 'Int',
            labelColor: 'String',
            buttonColor: 'String',
            sequence: 'Int',
            translations: 'LandingPageProductTranslations',
            media: '[Media]',
            rows: '[LandingPageRow]',
        },
    },
    landingPageProductTranslations: {
        nodeType: 'LandingPageProductTranslations',
        schema: {
            en: 'LandingPageProductTranslation',
            pl: 'LandingPageProductTranslation',
        },
    },
    landingPageProductTranslation: {
        nodeType: 'LandingPageProductTranslation',
        schema: {
            title: 'String',
            lead: 'String',
            caption: 'String',
            collection: 'String',
            label: 'String',
            brandUrl: 'String',
            productUrl: 'String',
        },
    },
    landingPageRow: {
        nodeType: 'LandingPageRow',
        schema: {
            id: 'Int',
            media: '[Media]',
            translations: 'LandingPageRowTranslations',
        },
    },
    landingPageRowTranslations: {
        nodeType: 'LandingPageRowTranslations',
        schema: {
            en: 'LandingPageRowTranslation',
            pl: 'LandingPageRowTranslation',
        },
    },
    landingPageRowTranslation: {
        nodeType: 'LandingPageRowTranslation',
        schema: {
            title: 'String',
            slug: 'String',
        },
    },
    landingPageTranslations: {
        nodeType: 'LandingPageTranslations',
        schema: {
            en: 'LandingPageTranslation',
            pl: 'LandingPageTranslation',
        },
    },
    landingPageTranslation: {
        nodeType: 'LandingPageTranslation',
        schema: {
            title: 'String',
            upTitle: 'String',
            bottomTitle: 'String',
            bottomSubTitle: 'String',
            slug: 'String',
        },
    },
    media: {
        nodeType: 'Media',
        schema: {
            fileId: 'Int',
            type: 'String',
            url: 'String',
            alt: 'String',
            relations: '[Int]',
            sequence: 'Int',
        },
    },
    navLink: {
        nodeType: 'NavLink',
        schema: {
            navLinkId: 'Int',
            as: 'String',
            type: 'String',
            to: 'String',
            labelKey: 'String',
            links: '[NavLinkDropdown]',
        },
    },
    navLinkDropdown: {
        nodeType: 'NavLinkDropdown',
        schema: {
            as: 'String',
            to: 'String',
            labelKey: 'String',
            links: '[NavLinkDropdown]',
        },
    },
    post: {
        nodeType: 'Post',
        schema: {
            articleId: 'Int',
            slug: 'String',
            isPromoted: 'Boolean',
            publishedAt: 'Int',
            translations: 'PostTranslations',
            media: '[Media]',
            author: 'Author',
            relatedPosts: '[Post]',
            relatedProducts: '[Product]',
            tags: '[Tag]',
            box1: 'PostBox',
            box2: 'PostBox',
            box3: 'PostBox',
        },
    },
    postBox: {
        nodeType: 'PostBox',
        schema: {
            articleId: 'Int',
            slug: 'String',
            viewType: 'Int',
            translations: 'PostBoxTranslations',
            media: '[Media]',
        },
    },
    postBoxTranslations: {
        nodeType: 'PostBoxTranslations',
        schema: {
            pl: 'PostBoxTranslation',
            en: 'PostBoxTranslation',
        },
    },
    postBoxTranslation: {
        nodeType: 'PostBoxTranslation',
        schema: {
            title: 'String',
            subtitle: 'String',
            lead: 'String',
            url: 'String',
            buttonText: 'String',
        },
    },
    postTranslations: {
        nodeType: 'PostTranslations',
        schema: {
            pl: 'PostTranslation',
            en: 'PostTranslation',
        },
    },
    postTranslation: {
        nodeType: 'PostTranslation',
        schema: {
            slug: 'String',
            title: 'String',
            lead: 'String',
            content: 'String',
            content2: 'String',
            content3: 'String',
        },
    },
    product: {
        nodeType: 'Product',
        schema: {
            productId: 'Int',
            slug: 'String',
            ean: 'JSON', // It should be an array of strings (think of it that way) but there is a strange bug then.
            isBestseller: 'Boolean',
            createdAt: 'Int',
            breadcrumbs: '[[Category]]',
            media: '[Media]',
            translations: 'ProductTranslations',
            relatedProducts: '[Product]',
            familyProducts: '[Product]',
            labelColor: 'String',
        },
    },
    productTranslations: {
        nodeType: 'ProductTranslations',
        schema: {
            en: 'ProductTranslation',
            pl: 'ProductTranslation',
        },
    },
    productTranslation: {
        nodeType: 'ProductTranslation',
        schema: {
            slug: 'String',
            name: 'String',
            shortName: 'String',
            shortDescription: 'String',
            description: 'String',
            label: 'String',
        },
    },
    redirect: {
        nodeType: 'Redirect',
        schema: {
            redirectId: 'Int',
            origin: 'String',
            destination: 'String',
            code: 'Int',
        },
    },
    ring: {
        nodeType: 'Ring',
        schema: {
            articleId: 'Int',
            sequence: 'Int',
            color: 'String',
            media: '[Media]',
            translations: 'RingTranslations',
            category: 'RingCategory',
        },
    },
    ringTranslations: {
        nodeType: 'RingTranslations',
        schema: {
            en: 'RingTranslation',
            pl: 'RingTranslation',
        },
    },
    ringTranslation: {
        nodeType: 'RingTranslation',
        schema: {
            title: 'String',
            lead: 'String',
            url: 'String',
        },
    },
    ringCategory: {
        nodeType: 'RingCategory',
        schema: {
            categoryId: 'Int',
            businessMeaning: 'Int',
        },
    },
    socialLink: {
        nodeType: 'SocialLink',
        schema: {
            linkId: 'Int',
            socialId: 'Int',
            type: 'Int',
            translations: 'SocialLinkTranslations',
        },
    },
    socialLinkTranslations: {
        nodeType: 'SocialLinkTranslations',
        schema: {
            en: 'SocialLinkTranslation',
            pl: 'SocialLinkTranslation',
        },
    },
    socialLinkTranslation: {
        nodeType: 'SocialLinkTranslation',
        schema: {
            url: 'String',
        },
    },
    socialWidgetItem: {
        nodeType: 'SocialWidgetItem',
        schema: {
            type: 'Int',
            links: '[SocialWidgetLink]',
        },
    },
    socialWidgetLink: {
        nodeType: 'SocialWidgetLink',
        schema: {
            translations: 'SocialWidgetLinkTranslations',
        },
    },
    socialWidgetLinkTranslations: {
        nodeType: 'SocialWidgetLinkTranslations',
        schema: {
            en: 'SocialWidgetLinkTranslation',
            pl: 'SocialWidgetLinkTranslation',
        },
    },
    socialWidgetLinkTranslation: {
        nodeType: 'SocialWidgetLinkTranslation',
        schema: {
            url: 'String',
            name: 'String',
        },
    },
    tag: {
        nodeType: 'Tag',
        schema: {
            tagId: 'Int',
            type: 'Int',
            slug: 'String',
            posts: '[Post]',
            translations: 'TagTranslations',
        },
    },
    tagTranslations: {
        nodeType: 'TagTranslations',
        schema: {
            en: 'TagTranslation',
            pl: 'TagTranslation',
        },
    },
    tagTranslation: {
        nodeType: 'TagTranslation',
        schema: {
            name: 'String',
            slug: 'String',
        },
    },
};
