import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    content,
    logo,
    contentBox,
    title,
    text,
    imgBox,
    img,
    mobile,
    desktop,
    button,
} from './under-construction.module.scss';
import { MainContext } from '../../layouts/main-layout.context';

import ButtonClose from '../atoms/button-close';

const logoDarkSrc = '../../assets/images/logo-verona-footer.png';
const modalDesktopImgSrc = '../../assets/images/modal-desktop.jpg';
const modalMobileImgSrc = '../../assets/images/modal-mobile.jpg';

const UnderConstruction: React.FC = () => {
    const { t } = useI18next();
    const { setShowModal } = useContext(MainContext);

    return (
        <div className={container}>
            <ButtonClose className={button} onClick={() => setShowModal(false)} />
            <div className={contentBox}>
                <StaticImage
                    src={logoDarkSrc}
                    alt="Verona Products Professional"
                    placeholder="blurred"
                    quality={100}
                    className={logo}
                />
                <div className={content}>
                    <h2 className={title}>{t('wip.title')}</h2>
                    <p className={text}>{t('wip.description')}</p>
                </div>
            </div>
            <div className={imgBox}>
                <StaticImage
                    src={modalDesktopImgSrc}
                    alt=""
                    className={`${img} ${desktop}`}
                    placeholder="blurred"
                    quality={100}
                    layout="fullWidth"
                />
                <StaticImage
                    src={modalMobileImgSrc}
                    alt=""
                    className={`${img} ${mobile}`}
                    placeholder="blurred"
                    quality={100}
                    layout="fullWidth"
                />
            </div>
        </div>
    );
};

export default UnderConstruction;
