// extracted by mini-css-extract-plugin
export var button = "under-construction-module--button--D-w4o";
export var container = "under-construction-module--container--H9Uet";
export var content = "under-construction-module--content--SHNtn";
export var contentBox = "under-construction-module--content-box--nl5g1";
export var desktop = "under-construction-module--desktop--zGhRX";
export var img = "under-construction-module--img--sor23";
export var imgBox = "under-construction-module--img-box--JxwUU";
export var logo = "under-construction-module--logo--xCnn5";
export var mobile = "under-construction-module--mobile--N7qyc";
export var text = "under-construction-module--text--0XQKn";
export var title = "under-construction-module--title--BgTyd";