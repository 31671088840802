module.exports = {
    author: ['firstName', 'lastName', 'slug', 'title', 'bio'],
    banner: ['title', 'description', 'buttonText', 'buttonUrl', 'media'],
    brandSocial: ['name'],
    careerOffer: ['title', 'taskContent', 'offerContent', 'media', 'location'],
    category: ['name', 'slug', 'shortDescription', 'description'],
    catalog: ['url', 'label'],
    certificate: ['name', 'url'],
    consent: ['content'],
    doc: ['name', 'path'],
    govModal: ['url', 'label'],
    instagramCard: ['title', 'buttonText'],
    landingPage: ['title', 'upTitle', 'bottomTitle', 'bottomSubTitle', 'slug'],
    landingPageProducts: [
        'title',
        'lead',
        'caption',
        'collection',
        'label',
        'brandUrl',
        'productUrl',
    ],
    landingPageBestseller: ['title'],
    landingPageRow: ['title', 'slug'],
    pageContext: ['label'],
    postCard: ['title', 'lead', 'media'],
    post: ['title', 'lead', 'content', 'content2', 'content3', 'media'],
    postBox: ['title', 'subtitle', 'lead', 'url', 'buttonText'],
    product: ['slug', 'name', 'shortName', 'shortDescription', 'description', 'ean', 'label'],
    ring: ['title', 'lead', 'url'],
    singleFaq: ['question', 'answer'],
    socialLink: ['url'],
    socialWidgetLink: ['url', 'name'],
    tag: ['name', 'slug'],
    video: ['url'],
};
