import { CookiesConfig, ModuleConfig } from 'alterpage-react-cookies';

export const cookies: CookiesConfig = {
    pl: [
        {
            title: 'Ciasteczka z witryny Youtube',
            description: 'Są niezbędne do prawidłowego odtwarzania filmów',
            group: 'necessary',
        },
        {
            title: 'Ciasteczka konfiguracyjne: AP_ALLOW, AP_ANALYTICS',
            description: 'Zapamiętują Twoją o decyzję dotyczącą o ustawień cookies',
            group: 'necessary',
        },
        {
            title: 'Google Analytics: _ga, _gat, _gid',
            description: 'Umożliwiają śledzenie ruchów Użytkowników w Serwisie',
            group: 'analytics',
        },
        {
            title: 'Hot Jar',
            description: 'Umożliwia tworzenie map kliknięć',
            group: 'analytics',
        },
        {
            title: 'Google Search Console',
            description: 'Służy do monitoringu Serwisu w wynikach wyszukiwania Google',
            group: 'analytics',
        },
        {
            title: 'Ciasteczka Google',
            description:
                'Zapamiętują informacje o Twojej wizycie, dzięki czemu podczas kolejnych odwiedzin witryna jest łatwiejsza w obsłudze',
            group: 'analytics',
        },
    ],
    en: [
        {
            title: 'Youtube cookies files',
            description: 'They are necessary for proper video playing',
            group: 'necessary',
        },
        {
            title: 'Config cookies: AP_ALLOW, AP_ANALYTICS',
            description: 'They save your choice about cookies',
            group: 'necessary',
        },
        {
            title: 'Google Analytics: _ga, _gat, _gid',
            description: "They enable the tracking of Users' movements on the Website",
            group: 'analytics',
        },
        {
            title: 'Hot Jar',
            description: 'Enables you to create clickmaps',
            group: 'analytics',
        },
        {
            title: 'Google Search Console',
            description: 'Used to monitor the Website in Google search results',
            group: 'analytics',
        },
        {
            title: 'Google cookies',
            description:
                'They remember information about your visit, thanks to which the website is easier to use on the next visits',
            group: 'analytics',
        },
    ],
};

export const cookiesConfig: ModuleConfig = {
    cookies: {
        use: ['analytics'],
    },
    privacyPolicyLink: {
        pl: '/pl/polityka-prywatnosci.pdf',
        en: '/privacy-policy.pdf',
    },
};
