import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, small, inputBox, input, labelBox } from './input.module.scss';
import { searchInput, clearButton, active, smallSearch } from './input-search.module.scss';

interface IInputSearchProps {
    className?: string;
    name: string;
    identifier?: string;
    placeholder?: string;
    label?: string;
    wait?: number;
    isSmall?: boolean;
    onChange?(value: string): void;
}

const InputSearch: React.FC<IInputSearchProps> = ({
    className = '',
    name,
    identifier = '',
    placeholder = '',
    label = '',
    wait = 0,
    isSmall = false,
    onChange = () => {},
}) => {
    const { t } = useI18next();
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>('');
    const [actualTimeout, setActualTimeout] = useState<NodeJS.Timeout | undefined>();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (actualTimeout) {
            clearTimeout(actualTimeout);
        }
        const newTimeout: NodeJS.Timeout = setTimeout(() => {
            onChange(newValue);
        }, wait);
        setValue(newValue);
        setActualTimeout(newTimeout);
    };

    const handleClear = () => {
        setValue('');
        onChange('');
        inputRef?.current?.focus();
    };

    useEffect(() => {
        return () => actualTimeout && clearTimeout(actualTimeout);
    }, [actualTimeout]);

    return (
        <div className={`${container} ${className} ${isSmall ? `${small} ${smallSearch}` : ''}`}>
            {label ||
                placeholder ||
                (t('browser.placeholder') && (
                    <label className={labelBox} htmlFor={`${name}${identifier}`}>
                        {label || placeholder || t('browser.placeholder')}
                    </label>
                ))}
            <div className={inputBox}>
                <input
                    ref={inputRef}
                    className={`${input} ${searchInput}`}
                    placeholder={placeholder || t('browser.placeholder')}
                    value={value}
                    onChange={handleChange}
                />
                <button
                    className={`${clearButton} ${value ? active : ''}`}
                    tabIndex={-1}
                    onClick={handleClear}
                    aria-label={t('browser.clear')}
                />
            </div>
        </div>
    );
};

export default InputSearch;
