import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { config } from '../../config';

import {
    container,
    mainButton,
    logos,
    text,
    modalOpened,
    exit,
    modalClosed,
    modalClosedDesktop,
    modalShowTextMobile,
    scrollingDown,
    scrollingUp,
    ratio,
    flag,
} from './gov-dialog.module.scss';
import { IGovModal } from '../../models/gov-modal.model';
import { isMobileDevice } from '../../utils/is-mobile-device';
import useWindowScroll from '../../hooks/use-window-scroll';
import { useTranslation } from '../../hooks/use-translation';

import Arrow from '../../assets/images/svg/arrow.svg';
import Exit from '../../assets/images/svg/exit.svg';
import EuFlag from '../../assets/images/svg/eu-flag.svg';

import RatioImage from '../atoms/ratio-image';
const { translationKeys } = config;

interface IGovDialogProps {
    className?: string;
}

interface IGovDialogStaticQueryResult {
    govModal: IGovModal;
}

const GovDialog: React.FC<IGovDialogProps> = ({ className = '' }) => {
    const { y } = useWindowScroll();

    const data: IGovDialogStaticQueryResult = useStaticQuery(query);
    const { status: isModalActive, media: modalMedia } = data?.govModal || {};

    const { url, label } = useTranslation(data.govModal, translationKeys.govModal);

    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isModalMobileClicked, setIsModalMobileClicked] = useState(false);
    const [isScrolledAtLeastOnce, setIsScrolledAtLeastOnce] = useState(false);
    const [isScrollingDownState, setIsScrollingDownState] = useState(false);

    const isMobile = isMobileDevice();
    const isScrollingDown = !isModalOpen && y < (window?.scrollY || 0);
    const isScrollingUp = !isModalOpen && y > (window?.scrollY || 0);
    const isSmallModalVisible = useRef(false);

    const timeoutRefHideModal = useRef<NodeJS.Timeout | null>(null);
    const timeoutRefShowSmallModal = useRef<NodeJS.Timeout | null>(null);

    const handleExit = () => {
        setIsModalOpen(!isModalOpen);
        isSmallModalVisible.current = true;
        if (timeoutRefHideModal.current) {
            clearTimeout(timeoutRefHideModal.current);
        }
    };

    useEffect(() => {
        if (isScrolledAtLeastOnce && isModalOpen) {
            const timeoutHideModal = setTimeout(() => {
                setIsModalOpen(!isModalOpen);
            }, 5000);
            timeoutRefHideModal.current = timeoutHideModal;
        }
    }, [isScrolledAtLeastOnce]);

    useEffect(() => {
        if (y < 20 || isScrolledAtLeastOnce) return;
        setIsScrolledAtLeastOnce(true);
    }, [y, isScrolledAtLeastOnce]);

    useEffect(() => {
        if (isSmallModalVisible.current) {
            const timeoutShowSmallModal = setTimeout(() => {
                isSmallModalVisible.current = false;
            }, 500);
            timeoutRefShowSmallModal.current = timeoutShowSmallModal;
            return;
        }
        setIsScrollingDownState(true);
    }, [isScrollingDown]);

    useEffect(() => {
        setIsScrollingDownState(false);
    }, [isScrollingUp]);

    useEffect(() => {
        return () => {
            if (timeoutRefHideModal.current) {
                clearTimeout(timeoutRefHideModal.current);
            }

            if (timeoutRefShowSmallModal.current) {
                clearTimeout(timeoutRefShowSmallModal.current);
            }
        };
    }, []);

    return isModalActive ? (
        <div className={`${className} ${container}`}>
            {isModalOpen && (
                <div className={modalOpened}>
                    <button className={exit} onClick={handleExit}>
                        <Exit />
                    </button>

                    <a href={url} target="_blank" rel="noreferrer" className={mainButton}>
                        <div className={logos}>
                            {modalMedia.map((img) => {
                                const image = img.remoteImage && getImage(img.remoteImage);

                                return image ? (
                                    <RatioImage
                                        alt={''}
                                        image={image}
                                        ratioClass={ratio}
                                        objectFit={'contain'}
                                        key={img.fileId}
                                    />
                                ) : null;
                            })}
                        </div>

                        <p className={text}>
                            <span>{label}</span> <Arrow />
                        </p>
                    </a>
                </div>
            )}

            {!isModalOpen && (
                <button
                    className={`
                        ${modalClosed}
                        ${isMobile ? '' : modalClosedDesktop}
                        ${isModalMobileClicked && isMobile ? modalShowTextMobile : ''}
                        ${isScrollingDownState ? scrollingDown : scrollingUp}
                    `}
                    onClick={
                        isMobile && !isModalMobileClicked
                            ? () => setIsModalMobileClicked(!isModalMobileClicked)
                            : () => {
                                  setIsModalOpen(!isModalOpen);
                                  setIsModalMobileClicked(!isModalMobileClicked);
                              }
                    }
                >
                    <EuFlag className={flag} />
                    {label}
                </button>
            )}
        </div>
    ) : null;
};

const query = graphql`
    query {
        govModal {
            translations {
                pl {
                    url
                    label
                }
                en {
                    url
                    label
                }
            }
            status
            media {
                ...mediaFields
                remoteImage {
                    childImageSharp {
                        gatsbyImageData(quality: 80, placeholder: BLURRED)
                    }
                }
            }
        }
    }
`;

export default GovDialog;
