import React, { CSSProperties } from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { getImage } from 'gatsby-plugin-image';

import {
    container,
    imgBox,
    imgWrapper,
    img,
    title,
    contentParagraph,
    arrow,
    ratio,
} from './search-item.module.scss';
import { IProduct } from '../../models/product.model';
import { getMediaWithRelation } from '../../utils/get-relation';
import { useTranslation } from '../../hooks/use-translation';
import { config } from '../../config';

import RatioImage from './ratio-image';

const { relations } = config;

interface ISearchItemProps {
    className?: string;
    item: IProduct;
    titleKey: string;
    subtitleKey?: string;
    descriptionKey?: string;
    descriptionPrefix?: string;
    imageObjectFit?: CSSProperties['objectFit'];
    hasRemoteImage?: boolean;
}

const SearchItem: React.FC<ISearchItemProps> = ({
    className = '',
    item,
    titleKey,
    subtitleKey = '',
    descriptionKey = '',
    descriptionPrefix = '',
    imageObjectFit = 'cover',
    hasRemoteImage = false,
}) => {
    const { media, slug } = item;
    const mediaItem = getMediaWithRelation(media, relations.mainImage);
    const translation = useTranslation(item, [titleKey, descriptionKey, subtitleKey]);

    return (
        <Link to={slug} className={`${container} ${className}`}>
            {hasRemoteImage ? (
                <RatioImage
                    image={mediaItem?.remoteImage && getImage(mediaItem.remoteImage)}
                    ratioClass={ratio}
                    alt={mediaItem?.alt || ''}
                    objectFit={imageObjectFit}
                />
            ) : (
                <div className={imgBox}>
                    <div className={imgWrapper}>
                        <img
                            className={img}
                            alt={mediaItem?.alt || ''}
                            src={mediaItem?.url}
                            style={{ objectFit: imageObjectFit }}
                        />
                    </div>
                </div>
            )}
            <div>
                <h3 className={title}>{translation[titleKey]}</h3>
                {subtitleKey && translation[subtitleKey] && (
                    <p className={contentParagraph}>{translation[subtitleKey]}</p>
                )}
                {descriptionKey && translation[descriptionKey] && (
                    <p className={contentParagraph}>
                        {descriptionPrefix}
                        {translation[descriptionKey]}
                    </p>
                )}
            </div>
            <span className={arrow} />
        </Link>
    );
};

export default SearchItem;
