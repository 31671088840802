import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { container, imageBox, ratio, small } from './header-logos.module.scss';
import { IMedia } from '../../models/media.model';
import { getImage } from 'gatsby-plugin-image';

import RatioImage from './ratio-image';

type THeaderLogosSize = 'normal' | 'small';

interface IHeaderLogosProps {
    className?: string;
    size?: THeaderLogosSize;
}

interface IHeaderLogosStaticQueryResult {
    headerLogos: {
        logo: IMedia | null;
        secondLogo: IMedia | null;
    };
}

const HeaderLogos: React.FC<IHeaderLogosProps> = ({ className = '', size = 'normal' }) => {
    const { headerLogos }: IHeaderLogosStaticQueryResult = useStaticQuery(query);
    const { logo, secondLogo } = headerLogos;

    const logoImage = logo?.remoteImage && getImage(logo.remoteImage);
    const secondLogoImage = secondLogo?.remoteImage && getImage(secondLogo.remoteImage);

    const sizeClass = sizeClasses[size];

    return (
        <div className={`${container} ${className} ${sizeClass}`}>
            {logo && (
                <RatioImage
                    className={imageBox}
                    ratioClass={ratio}
                    image={logoImage}
                    alt={logo.alt}
                    fallbackUrl={logo.url}
                    objectFit="contain"
                />
            )}
            {secondLogo && (
                <RatioImage
                    className={imageBox}
                    ratioClass={ratio}
                    image={secondLogoImage}
                    alt={secondLogo.alt}
                    fallbackUrl={secondLogo.url}
                    objectFit="contain"
                />
            )}
        </div>
    );
};

const sizeClasses: Record<THeaderLogosSize, string> = {
    normal: '',
    small: small,
};

const query = graphql`
    query {
        headerLogos {
            logo {
                url
                remoteImage {
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: BLURRED, width: 100)
                    }
                }
            }
            secondLogo {
                url
                remoteImage {
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: BLURRED, width: 100)
                    }
                }
            }
        }
    }
`;

export default HeaderLogos;
