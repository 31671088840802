import { IMedia } from "../models/media.model";
import { config } from "../config";

const { relations } = config

export function getMediaWithRelation(
    media: IMedia[],
    relation: number,
    useFallback = true,
    fallback: number = relations.mainImage
): IMedia | undefined {
    let mediaItem = media.find(item => item.relations.includes(relation));
    if (!mediaItem && useFallback) {
        mediaItem = media.find(item => item.relations.includes(fallback))
    }
    return mediaItem;
}

export function getAllMediaWithRelations(
    media: IMedia[],
    relations: number[],
): IMedia[] {
    return media.filter(item => item.relations.some(relation => relations.includes(relation)))
}