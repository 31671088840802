import React, { useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    dropdown,
    searchItem,
    loader,
    noResultsMessage,
    errorMessage,
} from './search-widget.module.scss';

import Dropdown from '../hoc/dropdown';
import InputSearch from '../atoms/form/input-search';
import SearchItem from '../atoms/search-item';
import useSearch from '../../hooks/use-search';
import Loader from '../atoms/loader';
import InfiniteScrollBox from '../hoc/infinite-scroll-box';

interface ISearchWidgetProps {
    className?: string;
}

const SearchWidget: React.FC<ISearchWidgetProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { setPhrase, getNextPage, results, status } = useSearch({
        perPage: 10,
        onResponse: () => setIsOpen(true),
    });

    const handleScroll = () => getNextPage();
    const handleCloseDropdown = () => setIsOpen(false);

    return (
        <div className={`${container} ${className}`}>
            <InputSearch name="search-widget" isSmall={true} wait={500} onChange={setPhrase} />
            <Dropdown isOpen={isOpen} closeFunction={handleCloseDropdown} className={dropdown}>
                {results.length > 0 && (
                    <InfiniteScrollBox onScroll={handleScroll}>
                        {results.map((item) => {
                            return (
                                <SearchItem
                                    key={`search-item-product-${item.productId}`}
                                    className={searchItem}
                                    item={item}
                                    titleKey="name"
                                    subtitleKey="shortName"
                                    descriptionKey="ean"
                                    descriptionPrefix="EAN: "
                                    imageObjectFit="contain"
                                />
                            );
                        })}
                        {status === 'loading' && results.length > 0 && (
                            <Loader className={loader} color="white" size="small" />
                        )}
                    </InfiniteScrollBox>
                )}
                {status === 'success' && !results.length && (
                    <div className={noResultsMessage}>{t('browser.no.results')}</div>
                )}
                {status === 'error' && !results.length && (
                    <div className={errorMessage}>{t('browser.error')}</div>
                )}
                {status === 'loading' && !results.length && (
                    <Loader className={loader} color="white" />
                )}
            </Dropdown>
        </div>
    );
};

export default SearchWidget;
