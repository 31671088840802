import React, { useRef } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import { menuLink, footerLink, active, notActive } from './nav-link.module.scss';
import INavLink from '../../models/nav-link.model';

type Props = {
    link: INavLink;
    className?: string;
    isFooterLink?: boolean;
    onClick?(): void;
};

const NavLink: React.FC<Props> = ({
    link,
    className = '',
    isFooterLink = false,
    onClick = () => {},
}) => {
    const { t } = useI18next();
    const linkRef = useRef<HTMLElement>(null);

    const { as, to = '', labelKey = '', label } = link;
    const localLabel = label || t(labelKey);

    const handleClick = () => {
        if (linkRef.current) {
            linkRef.current.blur();
        }
        onClick();
    };

    let tagProps = {};

    switch (as) {
        case 'link':
            tagProps = {
                to: to,
                activeClassName: active,
                partiallyActive: to !== '/',
            };
            break;
        case 'externalLink':
            tagProps = {
                href: to,
                target: '_blank',
                rel: 'noopener noreferrer',
            };
            break;
        case 'button':
            tagProps = {
                onClick: handleClick,
                ref: linkRef,
            };
            break;
    }

    const Tag = getTag(as);

    return (
        <Tag
            className={`
                ${isFooterLink ? footerLink : menuLink}
                ${className}
                ${as === 'element' ? notActive : ''}
            `}
            {...tagProps}
        >
            {localLabel}
        </Tag>
    );
};

function getTag(as: string | undefined): React.ElementType | typeof Link {
    switch (as) {
        case 'externalLink':
            return 'a';
        case 'button':
            return 'button';
        case 'element':
            return 'div';
        default:
            return Link;
    }
}

export default NavLink;
