import React, { useRef } from 'react';

import { button, white, violet, round } from './button.module.scss';
import { InteractiveElement } from '../../models/interactive-element.model';
import {
    getInteractiveElementProps,
    getInteractiveElementTag,
} from '../../utils/get-interactive-element-tag';

export type TButtonColor = 'white' | 'violet' | 'black';

export interface IButtonProps {
    as?: InteractiveElement;
    onClick?(): void;
    to?: string;
    className?: string;
    color?: TButtonColor;
    isRound?: boolean;
}

const Button: React.FC<IButtonProps & Record<string, any>> = ({
    as = 'button',
    onClick = () => {},
    to = '',
    className = '',
    color = 'black',
    children,
    isRound = false,
    ...rest
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClick = () => {
        if (buttonRef.current) {
            buttonRef.current.blur();
        }
        onClick();
    };

    const ButtonTag = getInteractiveElementTag(as);

    const buttonProps = getInteractiveElementProps(as, {
        onClick: handleClick,
        elementRef: buttonRef,
        to: to,
    });

    return (
        <ButtonTag
            className={`
                ${button}
                ${className}
                ${isRound ? round : ''}
                ${getColorStyle(color)}
            `}
            {...buttonProps}
            {...rest}
        >
            {children}
        </ButtonTag>
    );
};

function getColorStyle(color: IButtonProps['color']): string {
    switch (color) {
        case 'white':
            return white;
        case 'violet':
            return violet;
        default:
            return '';
    }
}

export default Button;
