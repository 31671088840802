import React from 'react';

import { container, ratio, imageBox, nameText } from './certificate-card.module.scss';
import { config } from '../../config';
import { ICertificate } from '../../models/certificate.model';
import { useTranslation } from '../../hooks/use-translation';
import { getMediaWithRelation } from '../../utils/get-relation';
import { getImage } from 'gatsby-plugin-image';

import RatioImage from '../atoms/ratio-image';

const { translationKeys, relations } = config;

interface ICertificateCardProps {
    className?: string;
    certificate: ICertificate;
}

const CertificateCard: React.FC<ICertificateCardProps> = ({ className = '', certificate }) => {
    const { media } = certificate;
    const { remoteImage, alt, url: imageUrl } =
        getMediaWithRelation(media, relations.mainImage) || {};
    const { name, url } = useTranslation(certificate, translationKeys.certificate);
    const Tag = url ? 'a' : 'div';
    const tagProps = url
        ? {
              href: url,
              target: '_blank',
              rel: 'noopener noreferrer',
          }
        : {};

    return (
        <Tag className={`${container} ${className}`} {...tagProps}>
            <RatioImage
                className={imageBox}
                ratioClass={ratio}
                image={remoteImage && getImage(remoteImage)}
                fallbackUrl={imageUrl}
                alt={alt}
            />
            <p className={nameText}>{name}</p>
        </Tag>
    );
};

export default CertificateCard;
