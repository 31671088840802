import React from 'react';

import { container } from './social-widget.module.scss';
import { TQueryAllResult } from '../../models/query-all-result.model';
import { ISocialWidgetItem } from '../../models/social-widget-item.model';
import { getNodes } from '../../utils/get-nodes';
import { graphql, useStaticQuery } from 'gatsby';

import SocialLink from '../atoms/social-link';

const BACKGROUND_COLOR = '#B2C55E';
const DROPDOWN_BACKGROUND_COLOR = '#C6DB6B';

interface ISocialWidgetProps {
    className?: string;
}

interface ISocialWidgetQueryResult {
    allSocialWidgetItem: TQueryAllResult<ISocialWidgetItem>;
}

const SocialWidget: React.FC<ISocialWidgetProps> = ({ className = '' }) => {
    const { allSocialWidgetItem } = useStaticQuery<ISocialWidgetQueryResult>(query);
    const socialWidgetItems = getNodes(allSocialWidgetItem);

    if (!socialWidgetItems.length) return null;

    return (
        <ul className={`${container} ${className}`} style={{ backgroundColor: BACKGROUND_COLOR }}>
            {socialWidgetItems.map((socialItem) => {
                return (
                    <li key={`social-widget-item-${socialItem.type}`}>
                        <SocialLink
                            item={socialItem}
                            dropdownBackgroundColor={DROPDOWN_BACKGROUND_COLOR}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

const query = graphql`
    query {
        allSocialWidgetItem(sort: { fields: type, order: ASC }) {
            edges {
                node {
                    ...socialWidgetItemFields
                }
            }
        }
    }
`;

export default SocialWidget;
