import React from 'react';
import Link from '../../plugins/gatsby-plugin-ap-i18next/src/link';

import { InteractiveElement } from '../models/interactive-element.model';

export function getInteractiveElementTag(as: InteractiveElement): React.ElementType | typeof Link {
    if (as === 'link') return Link;
    if (as === 'externalLink') return 'a';
    if (as === 'element') return 'div';
    return 'button';
}

interface IInteractiveElementProps {
    onClick?(): any;
    elementRef?: React.MutableRefObject<any>;
    to?: string;
    activeClassName?: string;
}

export function getInteractiveElementProps(
    as: InteractiveElement,
    props: IInteractiveElementProps = {}
) {
    const { onClick, elementRef, to, activeClassName = '' } = props;
    return {
        ...(as === 'button'
            ? {
                  onClick: onClick,
                  ref: elementRef,
              }
            : {}),
        ...(as === 'link'
            ? {
                  to: to,
                  activeClassName: activeClassName,
              }
            : {}),
        ...(as === 'externalLink'
            ? {
                  href: to,
                  target: '_blank',
                  rel: 'noopener norefferer',
              }
            : {}),
    };
}
