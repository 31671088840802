import React from 'react';

import { hamburger, opened, lineBox, line, top, middle, bottom } from './hamburger.module.scss';

interface Props {
    onClick(): void;
    open: boolean;
}

const Hamburger: React.FC<Props> = ({ onClick, open = false }) => {
    const handleClick = () => {
        if (typeof onClick !== 'function') return;
        onClick();
    };

    return (
        <button
            onClick={handleClick}
            className={`${hamburger} ${open ? opened : ''}`}
            aria-label={open ? 'Close menu' : 'Open menu'}
        >
            <span className={lineBox}>
                <span className={`${line} ${top}`} />
                <span className={`${line} ${middle}`} />
                <span className={`${line} ${bottom}`} />
            </span>
        </button>
    );
};

export default Hamburger;
