import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { grid } from '../../styles/grid.module.scss';
import {
    header,
    logoLink,
    loginLinkText,
    languageSelect,
    certificatesRow,
    certificateList,
    certificateItem,
    middleRow,
    middleContainer,
    navigationRow,
    navigation,
    actions,
    sideActions,
    separator,
    search,
} from './header.module.scss';
import { config } from '../../config';
import IPrimaryNavLink from '../../models/primary-nav-link.model';
import { TQueryAllResult } from '../../models/query-all-result.model';
import { ICertificate } from '../../models/certificate.model';
import { getNodes } from '../../utils/get-nodes';

import Navigation from '../molecules/navigation';
import Hamburger from '../atoms/hamburger';
import LanguageSelect from '../molecules/language-select';
import SearchWidget from '../molecules/search-widget';
import CertificateCard from '../molecules/certificate-card';
import Slider from '../hoc/slider';
import HeaderLogos from '../atoms/header-logos';

const logoSrc = '../../assets/images/verona-logo.png';
const { loginLink } = config;

interface Props {
    logoTag?: React.ElementType;
}

interface IHeaderQueryResult {
    allNavLink: TQueryAllResult<IPrimaryNavLink>;
    allCertificate: TQueryAllResult<ICertificate>;
}

const Header: React.FC<Props> = ({ logoTag }) => {
    const { t } = useI18next();
    const { allCertificate, allNavLink }: IHeaderQueryResult = useStaticQuery(query);
    const certificates = getNodes(allCertificate);
    const navLinks = getNodes(allNavLink);
    const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

    const handleCloseNavigation = () => setIsNavOpen(false);
    const handleOpenNavigation = () => setIsNavOpen(true);

    return (
        <header className={`${header}`}>
            {certificates.length > 0 && (
                <div className={`${certificatesRow} ${grid}`}>
                    <Slider
                        className={certificateList}
                        slidesCount={certificates.length}
                        settings={sliderSettings}
                        showButtons={false}
                    >
                        {certificates.map((certificate) => {
                            return (
                                <li
                                    key={`certificate-card-${certificate.certificateId}`}
                                    className={certificateItem}
                                >
                                    <CertificateCard certificate={certificate} />
                                </li>
                            );
                        })}
                    </Slider>
                </div>
            )}
            <div className={`${middleRow} ${grid}`}>
                <div className={middleContainer}>
                    <HeaderLogos />
                    <Link to="/" className={logoLink}>
                        <StaticImage
                            src={logoSrc}
                            alt="Verona Products Professional"
                            as={logoTag}
                            placeholder="blurred"
                            quality={100}
                        />
                    </Link>
                    <div className={actions}>
                        <SearchWidget className={search} />
                        <Hamburger onClick={handleOpenNavigation} open={isNavOpen} />
                        <div className={sideActions}>
                            <a
                                href={loginLink}
                                className={loginLinkText}
                                rel="noreferrer nofollow"
                                target="_blank"
                            >
                                {t('nav.login')}
                            </a>
                            <span className={separator} />
                            <LanguageSelect className={languageSelect} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${navigationRow} ${grid}`}>
                <Navigation
                    className={navigation}
                    navItems={navLinks}
                    isOpen={isNavOpen}
                    onClose={handleCloseNavigation}
                />
            </div>
        </header>
    );
};

const sliderSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    pauseOnHover: false,
    speed: 500,
    slidesToShow: 4,
    infinite: true,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

const query = graphql`
    query {
        allNavLink {
            edges {
                node {
                    ...navLinkFields
                }
            }
        }

        allCertificate {
            edges {
                node {
                    ...certificateFields
                }
            }
        }
    }
`;

export default Header;
