import React, { useRef } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { button, icon } from './button-close.module.scss';
import CloseIcon from '../../assets/images/svg/icon-close.svg';

interface IButtonCloseProps {
    className?: string;
    onClick?(): void;
}

const ButtonClose: React.FC<IButtonCloseProps> = ({ className = '', onClick = () => {} }) => {
    const { t } = useI18next();
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClick = (): void => {
        if (buttonRef.current) {
            buttonRef.current.blur();
        }
        onClick();
    };

    return (
        <button
            ref={buttonRef}
            className={`${button} ${className}`}
            aria-label={t('button.close')}
            onClick={handleClick}
        >
            <CloseIcon className={icon} />
        </button>
    );
};

export default ButtonClose;
