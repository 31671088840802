import React, { useCallback, useEffect, useRef, useState } from 'react';
import SlickSlider, { ResponsiveObject } from 'react-slick';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
    container,
    noNavButtons,
    small,
    slider,
    prev,
    next,
    dots,
    banner,
} from './slider.module.scss';

import ArrowButton from '../atoms/arrow-button';
import SliderDots from '../molecules/slider-dots';

export interface IRenderSlidesFunctionProps {
    currentSlide: number;
    isSlider?: boolean;
    slidesToShow: number;
}

type TRenderSlidesFunction = (props: IRenderSlidesFunctionProps) => React.ReactNode[];
type TSliderLayout = 'banner' | 'card';

export interface ISliderProps {
    children?: React.ReactNode[] | TRenderSlidesFunction;
    className?: string;
    slidesCount: number;
    settings: any;
    isSmall?: boolean;
    showButtons?: boolean;
    showDots?: boolean;
    layout?: TSliderLayout;
    onChange?(isChanging: boolean): void;
}

const Slider: React.FC<ISliderProps> = ({
    children,
    className = '',
    slidesCount,
    settings,
    isSmall = false,
    showButtons = true,
    showDots = false,
    layout = 'card',
    onChange = () => {},
}) => {
    const { t } = useI18next();
    const sliderRef = useRef(null);
    const [slideIndex, setSlideIndex] = useState<number>(0);
    const [isChanging, setIsChanging] = useState<boolean>(false);
    const [isSlider, setIsSlider] = useState<boolean>(false);
    const [currentSlidesToShow, setCurrentSlidesToShow] = useState(0);

    const layoutClass = layoutClasses[layout];

    const handleBeforeChange = (oldIndex: number, newIndex: number) => {
        if (oldIndex === newIndex) return;
        setIsChanging(true);
        setSlideIndex(newIndex);
        onChange(true);
    };

    const handleAfterChange = () => {
        onChange(false);
        setIsChanging(false);
    };

    const handleOnReInit = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const slidesToShow = sliderRef?.current?.innerSlider?.props.slidesToShow || 1;
        setCurrentSlidesToShow(getSlidesToShow());
        setIsSlider(slidesCount > slidesToShow);
    };

    const [sliderSettings, setSliderSettings] = useState({
        ...settings,
        infinite: false,
        beforeChange: handleBeforeChange,
        afterChange: handleAfterChange,
        onReInit: handleOnReInit,
    });

    const getPrevSlide = (): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sliderRef.current.slickPrev();
    };

    const getNextSlide = (): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sliderRef.current.slickNext();
    };

    const getSlidesToShow = useCallback(() => {
        if (!settings.responsive) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const currentBreakpoint = sliderRef.current.state.breakpoint;
        const responsiveSetting = settings.responsive.find(
            (item: ResponsiveObject) => item.breakpoint === currentBreakpoint
        );

        return (
            responsiveSetting?.settings?.slidesToShow ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            sliderRef?.current?.innerSlider?.props.slidesToShow ||
            1
        );
    }, [settings.responsive]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sliderRef.current.slickGoTo(slideIndex);
    }, [slideIndex]);

    useEffect(() => {
        if (isSlider && settings?.infinite) {
            setSliderSettings({
                ...sliderSettings,
                infinite: true,
            });
        }
    }, [isSlider]);

    useEffect(() => {
        const handleResize = () => {
            setCurrentSlidesToShow(getSlidesToShow());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getSlidesToShow]);

    return (
        <div
            className={`
                ${container} 
                ${className} 
                ${isSmall ? small : ''} 
                ${showButtons ? '' : noNavButtons}
                ${layoutClass}
            `}
        >
            <SlickSlider ref={sliderRef} className={slider} {...sliderSettings}>
                {renderChildren({
                    children,
                    currentSlide: slideIndex,
                    isSlider,
                    slidesToShow: currentSlidesToShow,
                })}
            </SlickSlider>
            {isSlider && showButtons && (
                <>
                    <ArrowButton
                        className={prev}
                        onClick={getPrevSlide}
                        ariaLabel={t('button.prev')}
                        disabled={isChanging}
                        typeStyle={layout === 'banner' ? 'blank' : 'normal'}
                    />
                    <ArrowButton
                        className={next}
                        onClick={getNextSlide}
                        rotateDeg={180}
                        ariaLabel={t('button.next')}
                        disabled={isChanging}
                        typeStyle={layout === 'banner' ? 'blank' : 'normal'}
                    />
                </>
            )}
            {isSlider && showDots && (
                <SliderDots
                    className={dots}
                    activeIndex={slideIndex}
                    count={slidesCount}
                    onClick={(dotIndex) => setSlideIndex(dotIndex)}
                    disabled={isChanging}
                />
            )}
        </div>
    );
};

const layoutClasses: Record<TSliderLayout, string> = {
    card: '',
    banner: banner,
};

interface IRenderChildrenProps extends IRenderSlidesFunctionProps {
    children: ISliderProps['children'];
}

const renderChildren = ({
    children,
    currentSlide,
    isSlider,
    slidesToShow,
}: IRenderChildrenProps) => {
    if (typeof children === 'function') {
        return children({ currentSlide, isSlider, slidesToShow });
    }

    return children;
};

export default Slider;
