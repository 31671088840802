import React, { useRef, useState } from 'react';
import { CookiesBar } from 'alterpage-react-cookies/';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    grid,
    small,
    bannerBox,
    scrollButton,
    scrollButtonLine,
    scrollButtonText,
} from '../styles/grid.module.scss';
import { bannerRatioNormal, bannerRatioHigh, cookiesModule } from './main-layout.module.scss';
import { MainContext } from './main-layout.context';
import { cookies, cookiesConfig as defaultCookiesConfig } from '../config/cookies';
import useCookiesTranslations from '../hooks/cookies/use-cookies-translations';

import Header from '../components/organisms/header';
import Footer from '../components/organisms/footer';
import Modal from '../components/hoc/modal';
import UnderConstruction from '../components/organisms/under-construction';
import Banner, { IBannerProps } from '../components/organisms/banner';
import Slider from '../components/hoc/slider';
import Breadcrumbs, { IBreadcrumbsProps } from '../components/molecules/breadcrumbs';
import GovDialog from '../components/molecules/gov-dialog';
import SocialWidget from '../components/molecules/social-widget';

const logoSrc = '../assets/images/verona-logo.png';

interface IMainLayoutProps {
    children?: React.ReactNode;
    className?: string;
    bannerRatio?: 'normal' | 'high';
    bannersData?: IBannerProps | IBannerProps[];
    gridSize?: 'small' | 'normal';
    isLogoMainHeading?: boolean;
    breadcrumbsConfig?: IBreadcrumbsProps;
    isModalGovOnPage?: boolean;
    showScrollButton?: boolean;
}

const MainLayout: React.FC<IMainLayoutProps> = ({
    children,
    className = '',
    bannerRatio = 'normal',
    bannersData,
    gridSize = 'normal',
    isLogoMainHeading = false,
    breadcrumbsConfig,
    isModalGovOnPage = false,
    showScrollButton = false,
}) => {
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const cookiesConfig = useCookiesTranslations(defaultCookiesConfig);
    const { language, t } = useI18next();
    const bannerBoxRef = useRef<HTMLDivElement>(null);

    const [showGovModal, setShowGovModal] = useState(false);

    const handleScroll = () => {
        if (!bannerBoxRef.current) return;
        window.scrollTo(0, bannerBoxRef.current.offsetTop + bannerBoxRef.current.offsetHeight + 50);
    };

    const handleOnDone = () => {
        setShowGovModal(true);
    };

    const onCookiesSaved = () => initializeAndTrack(location);

    return (
        <MainContext.Provider
            value={{
                showModal,
                setShowModal,
            }}
        >
            <Header logoTag={isLogoMainHeading ? 'h1' : undefined} />
            {bannersData && (
                <div className={bannerBox} ref={bannerBoxRef}>
                    {Array.isArray(bannersData) ? (
                        <Slider
                            slidesCount={bannersData.length}
                            settings={bannerSliderSettings}
                            showButtons={true}
                            showDots={true}
                            layout="banner"
                        >
                            {bannersData.map((bannerData, index) => {
                                return (
                                    <Banner
                                        key={`slide-banner-${index}`}
                                        isSlide={true}
                                        ratioClass={getBannerRatioClass(bannerRatio)}
                                        {...bannerData}
                                    />
                                );
                            })}
                        </Slider>
                    ) : (
                        <Banner ratioClass={getBannerRatioClass(bannerRatio)} {...bannersData} />
                    )}
                    {showScrollButton && (
                        <button className={scrollButton} onClick={handleScroll}>
                            <span className={scrollButtonText}>{t('banner.scroll')}</span>
                            <span className={scrollButtonLine} />
                        </button>
                    )}
                </div>
            )}
            {breadcrumbsConfig && breadcrumbsConfig.items.length > 0 && (
                <Breadcrumbs
                    className={`${breadcrumbsConfig.className} ${getGridSizeClass(gridSize)}`}
                    listClassName={breadcrumbsConfig.listClassName}
                    items={breadcrumbsConfig.items}
                />
            )}
            <main className={`${grid} ${getGridSizeClass(gridSize)} ${className}`}>{children}</main>
            <Footer />
            {showModal && (
                <Modal>
                    <UnderConstruction />
                </Modal>
            )}
            <CookiesBar
                Logo={
                    <StaticImage
                        src={logoSrc}
                        alt="Verona Products Professional"
                        placeholder="blurred"
                        quality={100}
                    />
                }
                cookies={cookies}
                config={cookiesConfig}
                onCookiesSaved={onCookiesSaved}
                commonClassName={cookiesModule}
                language={language}
                onDone={handleOnDone}
            />
            {isModalGovOnPage && showGovModal && <GovDialog />}
            <SocialWidget />
        </MainContext.Provider>
    );
};

function getBannerRatioClass(bannerRatio: IMainLayoutProps['bannerRatio']): string {
    if (bannerRatio === 'high') return bannerRatioHigh;
    return bannerRatioNormal;
}

function getGridSizeClass(size: IMainLayoutProps['gridSize']): string {
    if (size === 'small') return small;
    return '';
}

const bannerSliderSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    pauseOnHover: false,
    speed: 500,
    infinite: true,
};

export default MainLayout;
