// extracted by mini-css-extract-plugin
export var addressesBox = "footer-module--addresses-box--OW+aa";
export var alternative = "footer-module--alternative--mbe-O";
export var companyDataBox = "footer-module--company-data-box--Yd4t3";
export var companyDataTitle = "footer-module--company-data-title--e8wvw";
export var companyDataValue = "footer-module--company-data-value--FKaaR";
export var container = "footer-module--container--gQCpA";
export var contentRow = "footer-module--content-row--IWi+o";
export var copyRow = "footer-module--copy-row--0xQy3";
export var created = "footer-module--created--x4HzO";
export var createdLink = "footer-module--created-link--6Q-rC";
export var euBox = "footer-module--eu-box--Pl0qI";
export var footer = "footer-module--footer--ef16N";
export var linkText = "footer-module--link-text--fnXea";
export var linksBox = "footer-module--links-box--vWt7c";
export var linksTitle = "footer-module--links-title--j-Y8E";
export var logoLink = "footer-module--logo-link--Fd3fW";
export var logos = "footer-module--logos--AoqTP";
export var logosBox = "footer-module--logos-box--r4wMa";