import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

interface ISEOProps {
    description?: string;
    meta?: [];
    title?: string;
    image?: string;
    isArticle?: boolean;
}

const SEO: React.FC<ISEOProps> = ({ title, description, image, isArticle = false, children }) => {
    const { site } = useStaticQuery(query);
    const { pathname } = useLocation();
    const { languages, language, pagePaths, defaultLanguage } = useI18next();

    const { defaultTitle, defaultDescription, defaultImage, siteUrl } = site.siteMetadata;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: image || `${siteUrl}${defaultImage}`,
        url: `${siteUrl}${pathname}`,
    };

    return (
        <Helmet
            title={seo.title}
            {...(title
                ? {
                      titleTemplate: `%s | ${defaultTitle}`,
                  }
                : {})}
            htmlAttributes={{ lang: language }}
        >
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content={isArticle ? 'article' : 'website'} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />

            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />

            {languages
                .filter((lang) => language !== lang)
                .map((lang, index) => {
                    const path =
                        lang === defaultLanguage ? pagePaths[lang] : `/${lang}${pagePaths[lang]}`;
                    return (
                        <link
                            key={`href-link-${index}`}
                            rel="alternate"
                            href={`${siteUrl}${path}`}
                            hrefLang={lang}
                        />
                    );
                })}

            {children}
        </Helmet>
    );
};

const query = graphql`
    query {
        site {
            siteMetadata {
                defaultTitle
                defaultDescription
                defaultImage
                siteUrl
            }
        }
    }
`;

export default SEO;
