import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

interface IObjectToTranslate {
    [key: string]: any;
    translations?: {
        [key: string]: {
            [key: string]: any;
        };
    };
}

interface IObjectTranslations {
    [key: string]: any;
}

export function useTranslation(
    object: IObjectToTranslate | undefined | null,
    keys: string[],
    fallbackToDefault = false
): IObjectTranslations {
    const { language, defaultLanguage } = useI18next();
    return getTranslation({
        object,
        keys,
        fallbackToDefault,
        language,
        defaultLanguage,
    });
}

interface IGetTranslationConfig {
    object: IObjectToTranslate | undefined | null;
    keys: string[];
    fallbackToDefault: boolean;
    language: string;
    defaultLanguage: string;
}

export function getTranslation({
    object,
    keys,
    fallbackToDefault,
    language,
    defaultLanguage,
}: IGetTranslationConfig): IObjectTranslations {
    const translations: IObjectTranslations = {};
    if (object) {
        keys.forEach((key) => {
            let keyTranslation = object.translations?.[language]?.[key];
            if (!keyTranslation || (Array.isArray(keyTranslation) && !keyTranslation.length)) {
                keyTranslation = fallbackToDefault
                    ? object.translations?.[defaultLanguage]?.[key]
                    : object[key];
            }
            translations[key] = keyTranslation;
        });
    }
    return translations;
}
