import React from 'react';
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    footer,
    container,
    contentRow,
    companyDataBox,
    companyDataTitle,
    companyDataValue,
    logosBox,
    logos,
    linksBox,
    linksTitle,
    alternative,
    linkText,
    addressesBox,
    copyRow,
    logoLink,
    euBox,
    created,
    createdLink,
} from './footer.module.scss';
import { grid } from '../../styles/grid.module.scss';
import PhoneIcon from '../../assets/images/svg/icon-phone.svg';
import EmailIcon from '../../assets/images/svg/icon-email.svg';
import { IFooterData } from '../../models/footer-items.model';
import { getIsInternalLink } from '../../utils/get-is-internal-link';

import HeaderLogos from '../atoms/header-logos';

const logoSrc = '../../assets/images/verona-logo.png';
const iconEuFund01Src = '../../assets/images/icon-eu-fund-01.png';
const iconEuFund02Src = '../../assets/images/icon-eu-fund-02.png';
const BASE_URL = process.env.BASE_URL || '';

export interface IFooterQueryResult {
    footerData: IFooterData;
}

const Footer: React.FC = () => {
    const { t, language } = useI18next();
    const { footerData } = useStaticQuery<IFooterQueryResult>(query);
    const {
        footerItems: { footerLinks, contactData, companyData, addresses },
    } = footerData;

    return (
        <footer className={`${grid} ${footer}`}>
            <div className={container}>
                <div className={contentRow}>
                    <div className={logosBox}>
                        <Link to="/" className={logoLink}>
                            <StaticImage
                                src={logoSrc}
                                alt="Verona Products Professional"
                                quality={100}
                                placeholder="blurred"
                            />
                        </Link>
                        <HeaderLogos className={logos} size="small" />
                    </div>
                    {companyData && (
                        <div className={companyDataBox}>
                            <p className={companyDataTitle}>
                                {companyData.translations[language].title}
                            </p>
                            <p className={companyDataValue}>
                                NIP: {companyData.translations[language].nip}
                            </p>
                            <p className={companyDataValue}>
                                REGON: {companyData.translations[language].regon}
                            </p>
                        </div>
                    )}

                    {footerLinks && (
                        <div className={linksBox}>
                            <div>
                                <p className={linksTitle}>
                                    {footerLinks.translations[language].title}
                                </p>
                                <ul>
                                    {footerLinks.links.map((link) => {
                                        const { url, title } = link.translations[language];
                                        const isInternal = getIsInternalLink(url, BASE_URL);
                                        return (
                                            <li key={`footer-link-${link.id}`}>
                                                {isInternal ? (
                                                    <GatsbyLink className={linkText} to={url}>
                                                        {title}
                                                    </GatsbyLink>
                                                ) : (
                                                    <a
                                                        className={linkText}
                                                        href={url}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        {title}
                                                    </a>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            {contactData && (
                                <>
                                    <div>
                                        <p className={linksTitle}>{t('footer.contact.title.pl')}</p>
                                        <a
                                            href={`tel:${contactData.translations.pl.phone}`}
                                            className={linkText}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <PhoneIcon /> {contactData.translations.pl.phone}
                                        </a>
                                        <a
                                            href={`mailto:${contactData.translations.pl.email}`}
                                            className={linkText}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <EmailIcon /> {contactData.translations.pl.email}
                                        </a>
                                    </div>
                                    <div>
                                        <p className={linksTitle}>
                                            {t('footer.contact.title.eng')}
                                        </p>
                                        <a
                                            href={`tel:${contactData.translations.en.phone}`}
                                            className={linkText}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <PhoneIcon /> {contactData.translations.en.phone}
                                        </a>
                                        <a
                                            href={`mailto:${contactData.translations.en.email}`}
                                            className={linkText}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <EmailIcon /> {contactData.translations.en.email}
                                        </a>
                                    </div>
                                </>
                            )}
                        </div>
                    )}

                    <ul className={addressesBox}>
                        {addresses.map((address) => {
                            return (
                                <li key={`footer-address-${address.id}`}>
                                    <address>
                                        <p className={`${linksTitle} ${alternative}`}>
                                            {address.translations[language].title}
                                        </p>
                                        <p className={linkText}>
                                            {address.translations[language].street}
                                        </p>
                                        <p className={linkText}>
                                            {address.translations[language].city}
                                        </p>
                                        <p className={linkText}>
                                            {address.translations[language].country}
                                        </p>
                                    </address>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className={copyRow}>
                    <p>
                        Copyright © 2021 <strong>Verona Products Professional</strong>
                    </p>
                    <p className={created}>
                        Created by{` `}
                        <a
                            target="_blank"
                            href="https://alterpage.pl/"
                            className={createdLink}
                            rel="noopener noreferrer"
                        >
                            alterpage
                        </a>
                    </p>
                    <div className={euBox}>
                        <StaticImage
                            src={iconEuFund01Src}
                            alt="Fundusze Europejskie. Program Regionalny"
                            quality={100}
                            placeholder="blurred"
                        />
                        <StaticImage
                            src={iconEuFund02Src}
                            alt="Unia Europejska. Europejski Fundusz Rozwoju Regionalnego"
                            quality={100}
                            placeholder="blurred"
                        />
                    </div>
                </div>
            </div>
        </footer>
    );
};

const query = graphql`
    query {
        footerData {
            ...footerDataFields
        }
    }
`;

export default Footer;
