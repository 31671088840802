import { IBreadcrumb } from "../models/breadcrumb.model";

interface IObjectToExtractBreadcrumb {
    slug: string,
    pageKey?: string,
    translations: {
        [key: string]: {
            [key: string]: any,
        }
    },
    [key: string]: any,
}

export function getBreadcrumbs(
    items: IObjectToExtractBreadcrumb[],
    labelKey: string | string[] = 'label'
): IBreadcrumb[] {
    if (!items) return [];
    return items.map(item => {
        return getBreadcrumbItem(item, labelKey);
    });
}

export function getBreadcrumbItem(item: IObjectToExtractBreadcrumb, labelKey: string | string[] = 'label', usePageKey: boolean = false): IBreadcrumb {
    const translations: IBreadcrumb["translations"] = {};
    const labelKeys = Array.isArray(labelKey) ? labelKey : [labelKey]
    Object.keys(item.translations).forEach(key => {
        labelKeys.forEach(labelKeyItem => {
            translations[key] = {
                ...translations[key],
                [labelKeyItem]: item.translations[key][labelKeyItem],
            }
        })

    });
    return {
        labelKey: labelKey,
        slug: usePageKey && item.pageKey ? item.pageKey : item.slug,
        translations: translations,
    }
}