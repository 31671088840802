import React, { useRef } from 'react';

import { button, icon, showDisable } from './arrow-button.module.scss';
import NormalIcon from '../../assets/images/svg/arrow-circle.svg';
import BlankIcon from '../../assets/images/svg/arrow-blank.svg';
import { InteractiveElement } from '../../models/interactive-element.model';
import {
    getInteractiveElementProps,
    getInteractiveElementTag,
} from '../../utils/get-interactive-element-tag';

type TArrowButtonTypeStyle = 'normal' | 'blank';

interface IArrowButtonProps {
    className?: string;
    as?: InteractiveElement;
    to?: string;
    ariaLabel?: string;
    rotateDeg?: number;
    onClick?(): void;
    disabled?: boolean;
    disabledStyle?: boolean;
    typeStyle?: TArrowButtonTypeStyle;
}

const ArrowButton: React.FC<IArrowButtonProps> = ({
    className = '',
    as = 'button',
    to = '',
    ariaLabel = '',
    rotateDeg = 0,
    onClick = () => {},
    disabled = false,
    disabledStyle = true,
    typeStyle = 'normal',
}) => {
    const buttonRef = useRef<HTMLElement>(null);

    const handleClick = (): void => {
        if (buttonRef.current) {
            buttonRef.current.blur();
        }
        onClick();
    };

    const Icon = icons[typeStyle];
    const ArrowTag = getInteractiveElementTag(as);
    const arrowProps = getInteractiveElementProps(as, {
        to: to,
        elementRef: buttonRef,
        onClick: handleClick,
    });

    return (
        <ArrowTag
            className={`
                ${button}
                ${className}
                ${disabledStyle ? showDisable : ''}
            `}
            aria-label={ariaLabel}
            disabled={disabled}
            {...arrowProps}
        >
            <Icon className={icon} style={{ transform: `rotate(${rotateDeg}deg)` }} />
        </ArrowTag>
    );
};

const icons: Record<TArrowButtonTypeStyle, React.SVGFactory> = {
    blank: BlankIcon,
    normal: NormalIcon,
};

export default ArrowButton;
