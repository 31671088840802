const languages = require('./languages');
const menuLinks = require('./menu-links');
const endpoints = require('./endpoints');
const schemas = require('./schemas');
const relations = require('./relations');
const fileTypes = require('./file-types');
const statusMap = require('./status-map');
const places = require('./places');
const translationKeys = require('./translation-keys');
const categoryBusinessMeanings = require('./category-business-meanings');
const formContexts = require('./form-contexts');
const newsletters = require('./newsletters');
const queryParamsKeys = require('./query-params-keys');

const API_URL = process.env.API_URL;
const JSON_SERVER_URL = process.env.JSON_SERVER_URL;
const JSON_SERVER_PORT = process.env.JSON_SERVER_PORT;

exports.config = {
    categoryBusinessMeanings,
    endpoints: endpoints({
        API_URL: API_URL,
        JSON_SERVER: `${JSON_SERVER_URL}:${JSON_SERVER_PORT}`,
    }),
    fileTypes,
    formContexts,
    headerTopLink: 'https://www.hebe.pl/search?q=team%20x&prefn1=brand&prefv1=Ingrid',
    languages,
    loginLink: 'https://veronab2b.pl:1280/',
    menuLinks,
    newsletters,
    places,
    queryParamsKeys,
    relations,
    schemas,
    statusMap,
    translationKeys,
    youtubeVideoLink: 'https://www.youtube.com/watch?v=xtI_qjmOM9U',
};
