import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import { container, list, linkItem } from './breadcrumbs.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { IBreadcrumb, IFinalBreadcrumb } from '../../models/breadcrumb.model';
import { ISitePage } from '../../models/site-page.model';
import { getTranslation } from '../../hooks/use-translation';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';
import { getBreadcrumbItem } from '../../utils/get-breadcrumbs';

import BreadcrumbLink from '../atoms/breadcrumb-link';

export interface IBreadcrumbsProps {
    className?: string;
    listClassName?: string;
    items: IBreadcrumb[];
}

interface IBreadcrumbsQueryResult {
    homeSitePage: ISitePage;
    site: { siteMetadata: { siteUrl: string } };
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({
    className = '',
    listClassName = '',
    items,
}) => {
    const { t, language, defaultLanguage } = useI18next();
    const { homeSitePage, site }: IBreadcrumbsQueryResult = useStaticQuery(query);

    const breadcrumbs = getFinalBreadcrumbs(
        [getBreadcrumbItem(homeSitePage.context), ...items],
        language,
        defaultLanguage
    );

    const structuredBreadcrumbsData = getBreadcrumbsStructuredData(
        breadcrumbs,
        site.siteMetadata.siteUrl,
        language
    );

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{structuredBreadcrumbsData}</script>
            </Helmet>
            <nav className={`${container} ${grid} ${className}`} aria-label={t('breadcrumbs')}>
                <ol className={`${list} ${listClassName}`}>
                    {breadcrumbs.map((breadcrumb, index) => {
                        return (
                            <li key={`breadcrumb-${index}`} className={linkItem}>
                                <BreadcrumbLink
                                    breadcrumb={breadcrumb}
                                    isNotLink={index === breadcrumbs.length - 1}
                                />
                            </li>
                        );
                    })}
                </ol>
            </nav>
        </>
    );
};

function getBreadcrumbsStructuredData(
    breadcrumbs: IFinalBreadcrumb[],
    baseUrl: string,
    language: string
) {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs.map((breadcrumb, index) => {
            return {
                '@type': 'ListItem',
                position: index + 1,
                name: breadcrumb.label,
                ...(index < breadcrumbs.length - 1
                    ? {
                          item: `${baseUrl}${getPathFromSlug(breadcrumb.slug, language)}`,
                      }
                    : {}),
            };
        }),
    });
}

function getFinalBreadcrumbs(
    items: IBreadcrumb[],
    language: string,
    defaultLanguage: string
): IFinalBreadcrumb[] {
    return items.map((item) => {
        const { slug, labelKey = 'label' } = item;
        const keys = Array.isArray(labelKey) ? labelKey : [labelKey];
        const translation = getTranslation({
            object: item,
            fallbackToDefault: true,
            keys,
            language,
            defaultLanguage,
        });
        const label = Object.values(translation).join(' ');
        return {
            label: label,
            slug: slug,
        };
    });
}

const query = graphql`
    query {
        homeSitePage: sitePage(context: { pageKey: { eq: "/" } }) {
            ...sitePageFields
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`;

export default Breadcrumbs;
