import React from 'react';

import GBFlag from '../../assets/images/svg/flag-gb.svg';
import PLFlag from '../../assets/images/svg/flag-pl.svg';

interface Props {
    className?: string;
    language: string;
}

const Flag: React.FC<Props> = ({ className = '', language }) => {
    if (language === 'en') return <GBFlag className={className} />;
    if (language === 'pl') return <PLFlag className={className} />;
    return null;
};

export default Flag;
