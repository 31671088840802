import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { IMedia } from "../models/media.model";
import { useState } from "react";

interface IObjectWithMediaToTranslate {
    media: IMedia[],
    translations: {
        [key: string]: {
            media?: IMedia[],
        },
    },
    [key: string]: any,
}

function getMedia(
    object: IObjectWithMediaToTranslate,
    language: string,
    defaultLanguage: string,
    fallbackToDefault: boolean
): IMedia[] {

    let newMedia: IMedia[];
    let media = object.translations[language]?.media || [];
    let fallbackMedia = (fallbackToDefault ? object.translations[defaultLanguage]?.media : object.media) || [];

    if (!media.length) {
        newMedia = fallbackMedia;
    } else {
        const mediaRelations = media.map(item => item.relations).flat();
        newMedia = [
            ...media,
            ...fallbackMedia.filter((item => {
                const newItem = {...item};
                newItem.relations = newItem.relations.filter(relation => {
                    return !mediaRelations.includes(relation);
                })
                return newItem.relations.length > 0;
            }))
        ];
    }

    return newMedia;
}

export function useMediaTranslation(
    object: IObjectWithMediaToTranslate,
    fallbackToDefault: boolean = false,
): IMedia[] {

    const { language, defaultLanguage } = useI18next();
    const [media] = useState<IMedia[]>(getMedia(object, language, defaultLanguage, fallbackToDefault));

    return media;
}